import React, {Component} from 'react';
import {
	Modal
} from 'react-materialize';
import Login from './Forms/Login'
import Register from "./Forms/Register";
import ForgotPassword from "./Forms/ForgotPassword";
import axios from "axios";
import ResetPassword from "./Forms/ResetPassword";

class loginModal extends Component {
	constructor(props) {
        super(props);
		this.showLogin = this.showLogin.bind(this);
		this.showSignup = this.showSignup.bind(this);
		this.showForgotPassword = this.showForgotPassword.bind(this);
        this.state = {
			register : false,
			forgotPassword : false,
			token : null
        }

    }
	componentDidMount() {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let token = params.get('t');
		if(token){
			this.validateToken(token);
		}
	}
	validateToken(token){
		axios.post('/api/user/validate_token', {token : token}).then(res => {
			const resp = res.data;
			if(resp.Success){
				this.setState({
					token : token
				})
				document.getElementById("openModalBtn").click()
			}
		});

	}
	showLogin(e) {
		this.setState({register : false, forgotPassword : false, token : null})
	}
	showSignup(e) {
		this.setState({register : true, forgotPassword : false, token : null})
	}
	showForgotPassword(e) {
		this.setState({register : false, forgotPassword : true, token : null})
	}
	render (){
		return (
			<Modal
			trigger={this.props.trigger}
			className='modalTop'
			style={this.state.register ? {marginTop : "0"} : {}}
			id="loginModal"
			onCloseStart={() => { this.setState({token : null})}}
            >
				{!this.state.register ?
					this.state.forgotPassword ?
						<ForgotPassword
							showSignup={this.showSignup}
							showLogin={this.showLogin}
						/> :
						this.state.token ?
							<ResetPassword
								token={this.state.token}
								showSignup={this.showSignup}
								showLogin={this.showLogin}
							/>
							:
							<Login
								showSignup={this.showSignup}
								showForgotPassword={this.showForgotPassword}
								showLinks={true}
							/>
					:
					<Register
						showLogin={this.showLogin}
					/>
				}
				<button id="openModalBtn" className="modal-trigger" href="#loginModal" style={{display:"none"}}></button>
	        </Modal>
		)
    };
}
export default loginModal;