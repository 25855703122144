import React, { Component } from 'react';

import Table from '../../Table';

import {
    Container, Col, Row, Icon
} from 'react-materialize';
import SearchFormTable from '../SearchFormTable'
import axios from 'axios';
import Loading from '../Loading';
import ModalConfirm from '../ModalConfirm';


class User extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isfinding: false,
            data: [{}],
            source: { uri: "/api/admin/weekly-users" },
            find: { uri: "/api/admin/get-users" },
            delete: { uri: "/api/admin/delete-user" },
            modalData: {}

        };
        this.updateDataTable = this.updateDataTable.bind(this);
        this.setModalData = this.setModalData.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    setModalData(e){
        e.preventDefault();
        let target = e.currentTarget;
        let id = target.getAttribute('data-key');
        let email = target.getAttribute('data-email');
        this.setState({modalData: {id: id, email: email}});
    }

    deleteUser() {
        axios.post(this.state.delete.uri, this.state.modalData)
            .then(res => {
                if(res.status === 200)
                    alert('Deleted succesfull');
                else
                    alert('Something fail when try to delete');

                window.location.reload();
            })
            .catch(err => {
                alert('Something fail when try to delete');
                window.location.reload();
            });

    }

    buildData(data) {

        data.map(
            (item, key) => {
                item.Action = <>
                    <a href="#passModal"
                        className="admin-action modal-trigger"
                        data-key={item.id}
                        data-email={item.email}
                        onClick={this.setModalData}>
                        <Icon>
                            vpn_key
                        </Icon>
                    </a>
                    <a href="#deleteModal"
                        className="admin-action modal-trigger"
                        data-key={item.id}
                        data-email={item.email}
                        onClick={this.setModalData}>
                        <Icon>
                            delete
                        </Icon>
                    </a>
                </>;
            }
        );
        return data;
    }

    updateDataTable(data){
        let dataToTable = this.buildData(data);
        this.setState({
            data: dataToTable,
            isfinding:true
        });
    }

    async getData() {
        axios.get(this.state.source.uri)
            .then(res => {
                if (res.status === 200) {
                    let dataToTable = this.buildData(res.data);
                    this.setState({
                        data: dataToTable,
                        loading: false
                    });
                }

            })
            .catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
            })
    }

    /**
     * Validate whats be mount
     */
    componentDidMount() {
        if (!this.state.isfinding)
            this.getData();
    }

    render() {

        const content = this.state.loading
            ? <Loading />
            : <Table id="table-user" data={this.state.data} />

        return <Container className="admin-container">
            <ModalConfirm id="deleteModal"
                data={this.state.modalData}
                message={
                    "Are yo sure to delete the user "
                    + (this.state.modalData.email || '')
                }
                onConfirm={this.deleteUser} />
            <Row>
                <Col s={12} className="admin-box">
                    <h5>Find users</h5>
                        <SearchFormTable
                            onSearch={this.updateDataTable}
                            options={this.state.find}
                        />
                    {content}
                </Col>
            </Row>
        </Container>;
    }

}

export default User;