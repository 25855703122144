import React, { Component } from 'react';
import Navbar from '../../../components/Main/Navbar';
import './style.css';

class UpdatePolicy extends Component {
    constructor(){
        super();
        this.state = {
            user : ''
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount() {
        if(this.props.authenticated !== null)
        {
        this.setState({user : this.props.authenticated.username,email: this.props.authenticated.email});
        }
    }
    changeHandler(e){
        this.setState({ [e.target.name]: e.target.value});
    }
    render() {
        return (
            <div className='main-p'>
                
                <Navbar user={this.state.user} />
                <div className="container main-container-privacy">
                    <div className="privacy-container ">
                        <h4 className='center-align' >
                            Update Policy & Information
                        </h4>
                        <p>
                            For the past few weeks we've been implementing some changes to our Update Policy and User experience in an effort to better serve you and our growing user base. We encourage you to read the changes stated below.
                        </p>
                        <b>Update Policy</b>
                        <p>
                            Talents are now required to minimum 3 or more posts per week on their StreamX channels, and be actively answering members messages.
                        </p>
                        <p>
                            To increase transparency, here are the update policies for StreamX talents.
                        </p>
                        <b className='subtitleSection'>StreamX Talent Policy:</b>
                        <div>
                            <h6 >Talent Responsibilities</h6>
                                <ul>
                                <li>
                                    By creating a StreamX talent profile, you are agreeing to post 3 or more timely content to your profile and also to actively engage with your fans. 
                                </li>
                                <li>
                                     You represent and warrant that you own or have the necessary licenses, rights, consents and permissions to grant the licenses to video or photos that you post on StreamX. 
                                </li>
                                <li>
                                    You agree that you are solely responsible for maintaining and posting content and maintaining engagement on your channel. 
                                </li>
                                <li>
                                    You will be responsible for all refunds and expenses that may occur with respect to user refunds and complaints associated with your channel.
                                </li>
                                <li>
                                    You acknowledge that StreamX has the right to monitor any talents use of the Site, Content, Videos and Services, at its sole discretion for the purpose of operating the Site and the Services, to ensure Talents compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body.
                                </li>
                            </ul>                    
                        </div>
                        <div>
                            <h6 >What is the refund policy for subscriptions?</h6>
                            <p>
                                We have a 100% customer satisfaction expectations for current, active subscribers. As long as you have an existing subscription with us, if you are not satisfied with what you are able to view, we’ll refund you the latest subscription payment you made if it was done in less than 14 days.
                            </p>                            
                        </div>
                        <div>
                            <h6 >Canceling</h6>
                            <p>
                                You can cancel your subscription anytime from <b>support.ccbill.com</b>. To cancel via email, send a message to <b>support@streamx.tv</b> with the following information:
                                <ul>
                                    <li>
                                        Username
                                    </li>
                                    <li>
                                        Email address used when subscribing 
                                    </li>
                                    <li>
                                        Subscription ID 
                                    </li>
                                </ul>
                            </p>
                            <p>
                                When you cancel a subscription, you cancel only future charges associated with your subscription. You may notify us of your intent to cancel at any time, but the cancellation will become effective at the end of your current billing period. 
                            </p>
                            <p>
                                Have more questions about these changes or want to suggest new features? Check out our support page for answers or email us at support@streamx.tv 
                            </p>
                            Thank you!
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    &#169; Copyright streamx, 2019 All right reserved.
                </div>
            </div>
        );
    }
}

export default UpdatePolicy;