import React, { Component } from 'react';
import Navbar from '../../../components/Main/Navbar';

class Terms extends Component {
    constructor(){
        super();
        this.state = {
            user : ''
        }
    }
    componentDidMount() {
        if(this.props.authenticated !== null)
        {
        this.setState({user : this.props.authenticated.username,email: this.props.authenticated.email});
        }
    }
    
    render() {
        return (
            <div className='main-p'>
                <Navbar user={this.state.user} />
                <div className="container main-container-privacy">
                    <div className="privacy-container ">
                        <h4 className='center-align' >
                            Terms and Conditions of Service
                        </h4>
                        <p>
                            These Streamx Terms and Conditions of Service are a legal document that explains your rights and obligations as a user, subscriber, and member of Streamx. Please read it carefully.
                        </p>
                        <div>
                            <h6 >I. Introduction</h6>
                            <p>
                                The following contains the general terms and conditions of contract which govern your relationship with Streamx.tv ("Streamx"). The Streamx Terms and Conditions of Service ("Terms") constitutes a legal agreement between you and Streamx. We reserve the right to update and/or change these Terms as needed including but not limited to changes based on our community, content database, legal issues, or market trends. Any changes that are made to these Terms will not apply retroactively, and will not apply to any dispute of which both parties had actual notice on or prior to the date of the change or amendment. Except as otherwise stated in these Terms, all amended terms shall be effective immediately after they are posted. Additionally, a notice of change in Terms will be provided to you upon your access to the site after the Terms have been updated. At this time, you will be asked to read and affirmatively accept the amended Terms by clicking an "I accept" and/or an "I agree" box, or you may decline to accept the amended Terms.
                            </p>
                            <p>
                                 If you do not accept the amended Terms, you will be denied access to the site, and your relationship will be governed by the Terms as they existed at the time of your first acceptance of these Terms (or accepted any subsequent changes to these Terms).
                            </p>
                            <p>
                                By using or accessing this website in any way and/or selling, advertising, uploading, downloading, or submitting any content or media, or by utilization of any of the software, data feeds, or services available through the Streamx website, you expressly acknowledge and agree to be bound by these Terms and the Privacy Policy which is fully incorporated herein by this reference. These Terms apply to you and, if you are using this website on your client's behalf, to you and all of your clients. Should you disagree with anything stated in these Terms please discontinue your use of the website and erase all data you have uploaded and/or downloaded. Where Streamx has provided you with a translation of the English language version of these Terms, then you agree that the translation is provided for your convenience only and that the English language version of the Terms will govern your relationship with Streamx. If there is any contradiction between the English language version and its translation in a different language, then the English language version shall take precedence.
                            </p>
                        </div>
                        <div>
                            <b className='section'>SECTION XIII CONTAINS A BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER. IT MAY AFFECT YOUR LEGAL RIGHTS. PLEASE READ IT.</b>
                            <h6 >II. Definitions</h6>
                            <p>
                                <b>Profile Owner.</b> A Profile Owner is a content contributor who has registered for an account on Streamx, and this contributor is selling content for a monthly subscription fee.
                            </p>
                            <p>
                                <b>Active Subscriber.</b> An Active Subscriber is a person who has purchased a monthly recurring subscription on Streamx, and this person has not cancelled the subscription or been refunded their subscription price. The Active Subscriber must also not have been the subject of a credit card dispute.
                            </p>
                            <p>
                                <b>Revenue Share.</b> Revenue Share for a Profile Owner is the amount of money that is equal to the Active Subscribers as measured on the last day of the calendar month multiplied by the monthly subscription price set by the profile owner, which is not inclusive of the Streamx Service Fee. This number is then subject to subtraction for any credit card disputes/chargebacks and corresponding bank charges and dispute fees. The Revenue Share amount is also referred to as a Profile Owner's Streamx Earnings.
                            </p>
                            <p>
                                <b>$200 Minimum.</b> If a Profile Owner generates more than $200 in a calendar month from Active Subscribers as measured on the last day of the calendar month, then that profile owner will have met the $200 minimum threshold. If a profile owner meets or exceeds the $200 minimum threshold, then all Hosting Fees will be waived. So, there are no Hosting Fees whatsoever for a profile that exceeds the $200 minimum threshold. However, any profile that generates less than the $200 minimum threshold from active subscribers as measured on the last day of the calendar month shall be subject to a Hosting Fee of $200 per month. The $200 Hosting Fee will be deducted from the profile's earnings (if any) at the end of the month. Any profile with a negative hosting fee balance may be locked and/or deleted. Once a profile has been deleted, Streamx agrees to waive any negative Hosting Fee balance.
                            </p>
                            <p>
                                <b>Hosting Fee.</b> A fee associated with Streamx's overhead and costs related to the hosting of a Profile Owner account. A Hosting Fee will be charged for any profile that fails to meet the $200 minimum threshold in a calendar month. For any profile that meets or exceeds the $200 minimum threshold, the Hosting Fee will be waived in its entirety, so there will be a $0 hosting fee for any profile that meets or exceeds the $200 minimum threshold.
                            </p>
                        </div>
                        <div>
                            <h6 >III. Site Usage Agreement</h6>
                            <b>A. Your Use of This Site</b>
                            <p>
                                This site is owned and operated by Streamx. All media, audio, video, and related informational materials in any medium provided by Streamx hereunder, including text, captions, or information (collectively referred to as "Content") are owned by Streamx and/or by our Content providers, users, or members of our community. All Content is protected by US and international copyright laws. Streamx may restrict, terminate, or remove your access to this site at any time, or restrict, terminate, or remove any Content for any reason. Though we endeavor to provide accurate information, this site may contain technical or other mistakes, inaccuracies or typographical errors. Additionally, this site and any Content may include historically and culturally important Content and text that may contain subjects that reflect the social attitudes and circumstances of a particular time or location. You should be aware that your use of the site may expose you to Content that may seem inappropriate to its context, or text that could be potentially offensive. You are solely responsible for determining whether your use of any Content requires the consent of any third party or the license of any additional rights, and you should not rely solely on the information provided by Streamx or its members. If you are unsure whether additional third-party rights are needed for your use, you are responsible for consulting with competent rights management professionals or legal counselors. Streamx makes all reasonable efforts to comply with and respond to all issues regarding potential copyrights and trademarks available on the US or international market. While the responsibility for the Content uploaded by users is entirely that of the user, we endeavor to identify and respond to any copyright or trademark infringements. If you have identified a file that you believe represents an infringement please notify us, so that we may take the appropriate measures.
                            </p>
                            <b>B. Content Submissions</b>
                            <p>
                                As part of the services offered and as consideration for your assent to these Terms, Streamx permits the submission of video and other communications, as well as the posting, hosting, sharing, and publishing of those submissions (Content). You understand and agree that whether or not such submissions are published and/or uploaded, the site does not guarantee any confidentiality with respect to any submissions.
                            </p>
                            <p>
                                You acknowledge and agree that you are solely responsible for any and all submissions made by you, and any and all consequences related to such submission. You further represent, affirm, and warrant:
                                <ul>
                                    <li>
                                        You own, retain, or have been granted the necessary licenses, rights, consents, and permissions to publish Content you submit. Your acceptance of these Terms also grants license to Streamx for all patent, trademark, trade secret, copyright or other proprietary rights in and to such Content for publication and dissemination on the services provided by the site pursuant to these Terms to enable inclusion and use of the Content in the manner contemplated by Streamx and these Terms.
                                    </li>
                                    <li>
                                        You will not post, or allow anyone else to post, any material of an adult or sexual nature that depicts any person under the age of 18 years. You further represent that you have inspected and are maintaining written documentation sufficient to confirm that all subjects of your submission are, in fact, over the age of 18 years.
                                    </li>
                                    <li>
                                        You possess the written consent, release, and/or permission of each and every identifiable individual person in the submission to use the name or likeness of each and every such identifiable person to enable use and inclusion of said persons in the submission in a manner contemplated by the site and these Terms.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                By submitting Content to the site, you hereby acknowledge and grant Streamx a worldwide, non-exclusive, royalty-free, sublicenseable and transferrable license to use, reproduce, distribute, prepare derivative works of, display, and perform the submission/Content in connection with the site and the site's business, including without limitation for promoting and redistributing part or all of site (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each user of the site a non-exclusive license to access your submission/Content through the site, and to use, reproduce, distribute, prepare derivative works of, display, and perform such submissions/Content as permitted through the functionality of the site and under these Terms. The foregoing license granted by you terminates once you remove or delete a submission from the site.
                            </p>
                            <p>
                                By and through the act of submitting Content, you further acknowledge and agree that you shall not:
                                <ul>
                                    <li>
                                        Submit material that is copyrighted, protected by trade secret, or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission form their rightful owner to post the material and to grant the site all of the license rights granted therein.
                                    </li>
                                    <li>
                                        Publish falsehoods or misrepresentations that could damage the site or any third party.
                                    </li>
                                    <li>
                                        Submit material that is obscene, illegal, unlawful, defamatory, libelous, harassing, hateful, racially or ethnically offense, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is otherwise inappropriate.
                                    </li>
                                    <li>
                                        Post advertisements or solicitations of business.
                                    </li>
                                    <li>
                                        Impersonate another person.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Streamx does not endorse any submission/content or any opinion, recommendation, or advice expressed therein, and Streamx expressly disclaims any and all liability in connection with such submissions/Content. Streamx does not permit copyright infringing activities and infringement of intellectual property rights on the site, and Streamx will remove all Content and submissions if properly notified that such Content or submission infringes on another's intellectual property rights. Streamx reserves the right to remove Content and submissions without prior notice or delay. Streamx will terminate a user's access to its site if they are determined to be an infringer. While all legal content is accepted, Streamx also reserves the right to decide in its sole and unfettered discretion, whether Content/submissions are appropriate and complies with these Terms for violations other than copyright infringement and violations of intellectual property law; including, but not limited to obscene or defamatory material, posting or promoting pornographic materials, promoting illegal activities or unlawful actions, promotion or sale of controlled substances and pharmaceuticals, the impersonation or misrepresentation of any individual or entity, and currency exchange between parties. Streamx may remove such Content/submissions and/or terminate a user's access for uploading such material in violation of these Terms at any time, without prior notice, and at its sole and unfettered discretion, decide whether posted content violates these Terms.
                            </p>
                            <b>C. Conduct</b>
                            <p>
                                You acknowledge and agree that you are solely responsible for your own Content along with all consequences and repercussions of posting, uploading, publishing, transmitting, or otherwise making your Content available on the site. By accepting these Terms, you affirm, warrant, and represent that you shall not post, upload, publish, transmit, or make available in any way Content which is illegal, unlawful, harassing, harmful, threatening, tortuous, abusive, defamatory, obscene, libelous, or invasive of one's privacy. You also agree that you shall not post, upload, publish, transmit, or make available in any way software containing viruses or any other computer code, files, or programs designed to destroy, interrupt, limit the functionality of, monitor, or persistently reside in or on any computer software or hardware telecommunications equipment.
                            </p>
                            <p>
                                The site may not be used as a payment processor to facilitate content sales and/or distribution on a 3rd party platform and/or application that is not owned by Streamx. For example, the attempt to sell a password, or the selling of participation in a private account on a 3rd party application in exchange for payment on Streamx is forbidden. Any attempt to engage in selling access to a 3rd party application by using Streamx as a payment processor will not be tolerated, and such activity will result in the termination of the account and the forfeiture of all money in the account. The money in the violated account will be used at the sole discretion of Streamx, and may become the property of Streamx at its sole discretion. Notwithstanding the foregoing, Streamx reserves the right to refund any unpaid Revenue Share earnings to you in its sole discretion.
                            </p>
                            <p>
                                The site may not be used as a payment processor to facilitate content sales and/or distribution on a 3rd party platform and/or application that is not owned by Streamx. For example, the attempt to sell a password, or the selling of participation in a private account on a 3rd party application in exchange for payment on Streamx is forbidden. Any attempt to engage in selling access to a 3rd party application by using Streamx as a payment processor will not be tolerated, and such activity will result in the termination of the account and the forfeiture of all money in the account. The money in the violated account will be used at the sole discretion of Streamx, and may become the property of Streamx at its sole discretion. Notwithstanding the foregoing, Streamx reserves the right to refund any unpaid Revenue Share earnings to you in its sole discretion.
                            </p>
                            <b>D. Prohibition by Law</b>
                            <p>
                                You agree that your use of this website and any Content shall comply with all applicable law. It is your responsibility to ensure that your use of the site does not violate any federal, state, provincial, or local laws or regulations applicable to your use of the site. You agree that you shall not post, publish, upload, or make available in any way Content which is intentionally or unintentionally violating any applicable local, state, national, or international law, or any regulations or laws having the force of law where you reside or elsewhere; including, but not limited to, any laws related to securities, privacy, and export control. You agree that you shall not post, publish, transmit, or otherwise make available in any way content depicting illegal activities, promote or depict physical harm or injury against any group or individual, or promote or depict any act of cruelty to animals. You agree not to use this site in any way that exposes the Streamx to criminal or civil liability.
                            </p>
                            <b>E. Children</b>
                            <p>
                                Children (persons under the age of 18) are not eligible to use this site unsupervised and children are prohibited from registering for an account, submitting any personal information to the site, or submitting any Content to the site in any way. In accessing or utilizing this site in any way, you warrant and affirm that you are 18 years of age or older.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    &#169; Copyright streamx, 2019 All right reserved.
                </div>
            </div>
        );
    }
}

export default Terms;