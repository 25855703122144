import React, {Component} from 'react';
import axios from "axios";
import {Icon, Modal,Button} from 'react-materialize';


class CancelSubscription extends Component {
    constructor(props) {
        super(props);
        this.cancelSubscription = this.cancelSubscription.bind(this);

    }
    async cancelSubscription(e){
        e.preventDefault();
        let btnContainer = document.getElementById('cancelSubscriptionContainer');
        btnContainer.innerHTML = '<p class="tab-title">Processing...</p>';
        await axios.post('/api/transaction/cancel/', {
            sub_id: this.props.sub_id_to_cancel
        })
            .then(async (resp) => {
                btnContainer.innerHTML = '<p class="tab-title">Your subscription was successfully canceled, it still does not expire.</p>';
                setTimeout(() => {
                    window.location.reload();
                }, 3500);
            }).catch(err => {
                btnContainer.innerHTML = '<p class="tab-title">Can\'t cancel your subscription try later.</p>';
                setTimeout(() => {
                    window.location.reload();
                }, 3500);
            });
        return true;
    }

    render (){
        return (
            <Modal className="modal-confirm" id='cancelModal'>
                <span className="close modal-close">
                    <Icon>cancel</Icon>
                </span>
                <p>Are you sure to cancel the subscription?</p>
                <div id="cancelSubscriptionContainer" className="actions">
                    <ul>
                        <li>
                            <Button className="go" modal="close" waves="light">
                                No
                            </Button>
                        </li>
                        <li>
                            <Button
                                className="cancel"
                                onClick={this.cancelSubscription}>Yes
                            </Button>
                        </li>
                    </ul>
                </div>
            </Modal>
        )
    };
}
export default CancelSubscription;