import React, {Component} from 'react';
import {Icon} from 'react-materialize';
import AudioPlayer from "react-h5-audio-player";
import './style.css';

class ModalOpenSource extends Component {
    componentDidMount() {
        
    }
    
    render (){
        return (
            <div className="open-source">
                <span className="close" onClick={(e) => this.props.closeSource(e)}><Icon>close</Icon></span>
                {this.props.source ?
                    this.props.source.type === "Image" ?
                        <img alt='srcimg' src={this.props.source.src} />
                        : 
                        this.props.source.type === "Video" ? 
                            <video preload="yes"
                                   className="responsive-video"
                                   controls
                                   controlsList="nodownload"
                                   src={this.props.source.src} type="video/mp4"
                                   poster={this.props.source.poster}
                            >
                                Your browser does not support the video tag.
                            </video>
                            :
                            <AudioPlayer
                                src={this.props.source.src}
                                volume={0.9}

                            />
                    :
                    null
                }
            </div>
        )
    };
}
export default ModalOpenSource;