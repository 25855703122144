import React, { Component } from 'react';
import {Button, Row, TextInput} from "react-materialize";
import axios from "axios";

class ResetPassword extends Component {
    constructor() {
        super();
        this.changeHandler = this.changeHandler.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.state = {
            pwdFirst : '',
            pwdSecond : '',
            recovery_error : null,
            recovery_success : null
        }
    }
    resetPassword() {
        this.setState({recovery_error : null, recovery_success : null})
        const pwdFirst = this.state.pwdFirst;
        const pwdSecond = this.state.pwdSecond;
        let send = true;
        if(!pwdFirst || !pwdFirst.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#^().+=_~/'"-]{6,}$/g)){
            document.getElementById("pwdFirst").classList.add("has-error");
            this.setState({recovery_error : "Password must have at least 6 characters, one uppercase letter, one lowercase and one number."})
            send = false;
        }
        else if(!pwdSecond || pwdSecond !== pwdFirst){
            document.getElementById("pwdSecond").classList.add("has-error");
            this.setState({recovery_error : "Second password doesn't match"})
            send = false;
        }
        if(send){
            axios.post('/api/user/reset_password', {pwdFirst : pwdFirst, pwdSecond : pwdSecond, token : this.props.token}).then(res => {
                const resp = res.data;
                if(resp.Success){
                    window.location = "/"
                }
                else if(resp.Error){
                    this.setState({recovery_error : resp.Error});
                }
            });
        }
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
        /////VALIDAR CONTRASENAS ANTES DE CAMBIARLAS
    }
    componentDidMount() {
    }
    render() {
        return (
            <Row>
                <button href="#!" className="modal-close waves-effect btn-flat close-modal">
                    <span>x</span>
                </button>
                <h3>Reset Password</h3>
                <TextInput
                    name="pwdFirst"
                    id="pwdFirst"
                    s={12}
                    label='PASSWORD'
                    onChange={this.changeHandler}
                    validate
                    minLength={6}
                    maxLength = {30}
                    type='password'
                >
                </TextInput>
                <TextInput
                    name="pwdSecond"
                    id="pwdSecond"
                    s={12}
                    label='CONFIRM PASSWORD'
                    onChange={this.changeHandler}
                    validate
                    minLength={6}
                    maxLength = {30}
                    type='password'
                >
                </TextInput>
                <div className="actions-modal">
                    <Button
                        onClick={this.resetPassword}
                        waves='light'
                    >
                        GO
                    </Button>
                    {this.state.recovery_error ?
                        <small className="error-message error-class2">{this.state.recovery_error}</small>
                        : null
                    }
                    {this.state.recovery_success ?
                        <small className="success-message">{this.state.recovery_success}</small>
                        : null
                    }
                    <p className="col s12 divider-actions">
                        <hr/>
                        <span>OR</span>
                    </p>
                    <ul className="btn-group">
                        <li>
                            <a onClick={this.props.showSignup} href='#SignIn'>SIGN UP</a>
                        </li>
                        <li> | </li>
                        <li>
                            <a onClick={this.props.showLogin} href='#SignIn'>SIGN IN</a>
                        </li>
                    </ul>
                </div>
            </Row>
        );
    }
}

export default ResetPassword;