import React, {Component} from 'react';
import {
    Link
} from 'react-router-dom';
import {
	Dropdown,
    Navbar as Nav
} from 'react-materialize';
import './style.css';
import Login from "../login";
import Catalog from "../Catalog";
import axios from "axios";
import ProfileSettings from "../Profile/ProfileSettings";
class Navbar extends Component {
	constructor(props){
		super();
		this.state = {
			openMenu: false
		}
		this.openMenu = this.openMenu.bind(this);
	}
	openMenu = (e) => {
		e.preventDefault()
		const actual = this.state.openMenu;
		this.setState({openMenu : !actual});
	}
	logout() {
        axios.get('/api/user/logout').then(res => {
            window.location = "/";
        });
    }

    /**** DRAG ****/

	moveMenu(e){
		//let pos3 = e.clientX;
	    //let pos4 = e.clientY;
		e.target.onmouseup = this.closeDragElement;
	    e.target.onmousemove = this.elementDrag;
	}
	elementDrag(e) {
		let pos3 = e.clientX;
	    //let pos4 = e.clientY;
	    e = e || window.event;
	    e.preventDefault();
	    // calculate the new cursor position:
	    let pos1 = pos3 - e.clientX;
	    //let pos2 = pos4 - e.clientY;
	    pos3 = e.clientX;
	    //pos4 = e.clientY;
	    // set the element's new position:
	    e.target.style.left = (e.target.offsetLeft - pos1) + "px";
	    //console.log(e.target.offsetLeft, pos1)
	}
	closeDragElement() {
	    /* stop moving when mouse button is released:*/
	    document.onmouseup = null;
	    document.onmousemove = null;
	}

    /**** DRAG ***/
	render (){
		return (
			<div className="nav-container">
				<Nav className="nav-left font115">
					{!this.props.user ?
						<>
							<li><Link to='/'><span className="">Home</span></Link></li>
							<li><Link to='/About/Support'>Contact</Link></li>
							<li className="onlySmall"><a href="#loginModal" className="modal-trigger">Login</a></li>
						</>
						:
						<>
							<li>
								<Link to='/'><span className="">Home</span></Link>
							</li>
							<li>
								<Link to={"/" + this.props.user["username"] + "/Feed/Messages"}>
									<span className="">Messages</span>
								</Link>
							</li>
							<li>
								<Link to={"/" + this.props.user["username"]}>
									My Profile
								</Link>
							</li>
							<li>
								<Link to={"/" + this.props.user["username"] + "/Feed"}>
									My Account
								</Link>
							</li>
							<li>
								<a href="#LogOut" onClick={this.logout}>Logout</a>
							</li>
						</>
					}
				</Nav>
				<div className="nav-right">
					<div className="nav-wrapper search-nav">
      					<form>
      						{!this.props.user ?
								<Login
									token={this.props.token ? this.props.token : null}
									unsetToken={this.props.unsetToken ? this.props.unsetToken : () => {return false}}
									showModalBig={true}
									trigger={<button className="z-depth-4 login-btn" waves='light'>Login</button>} />
								:
								<Dropdown trigger={
									<button className="z-depth-4 login-btn user-button" waves='light' id="show-drop-menu">
										<span className="image" style={{backgroundImage : "url(" + this.props.user["user_profile.file.uri"] + ")" }}></span>
										<span className="text">{this.props.user.username}</span>
									</button>
								}
								options={{
									alignment : "left",
									closeOnClick: false,
								}}>
									<Link to={"/" + this.props.user["username"] + "/Feed/Messages"}>
										Messages
									</Link>
									<ProfileSettings trigger={
											<Link to="#ProfileSettings">
												Profile Settings
											</Link>
										}
									/>
									<Link to={"/" + this.props.user["username"]}>
										My Profile
									</Link>
									<Link to={"/" + this.props.user["username"] + "/Feed"}>
										My Account
									</Link>
									<li>
										<a href="#LogOut" onClick={this.logout}>
											Logout
										</a>
									</li>
								</Dropdown>
							}
							<div className="center-search main-nav input-field" onClick={this.props.openCatalog}>
								<input id="search" type="search" className="" required placeholder="Search your favorite Artist"/>
								<label className="label-icon" htmlFor="search">SEARCH</label>
							</div>
							{ this.props.showCatalog
								? <Catalog
									closeCatalog={this.props.closeCatalog}
									models={this.props.models}
									categories={this.props.categories}
									user={this.props.user}/>
								: null
							}
						</form>
    				</div>
				</div>
			</div>
		)
    };
}
export default Navbar;