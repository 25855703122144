import React from 'react';
import {Icon, Textarea, Button, Preloader} from 'react-materialize';
import moment from 'moment';
import AudioPlayer from "react-h5-audio-player";

const Posts = ({posts, imgProfile, username, owner = false, showSubmenu, toDeletePost, toEditPost, noEditPost, editPost, likePost, unlikePost,renderLightBox})=>{

    return posts.map((item, index) =>{
        return (
            <div key={index} className='content-post'>
                <div className='head-post'>
                    <div className='user-pic-post'>
                        <img src={imgProfile} alt=""/>
                    </div>
                    <div className='detail-post'>
                        <ul>
                            <li>
                            <span className='left strmx-color'>
                                {username}
                            </span>
                            {owner ?
                                <div>
                                    <span className='right pointer' onClick={(e) => showSubmenu(e)}><Icon >more_vert</Icon></span>
                                    <ul className="submenu-post">
                                        <li onClick={(e) => toEditPost(e, index)}>Edit Post</li>
                                        <li onClick={(e) => toDeletePost(e, item['id'])}>Delete Post</li>
                                    </ul>
                                </div>
                                : null
                            }
                            </li>
                            <li>
                                <span className='left time'>{moment(item.createdAt).fromNow()}</span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        {item['edit'] ?
                            <div className="edit-post">
                                <Textarea s={12} placeholder="Write your thought..." id="postText" defaultValue={item.caption}/>
                                <ul>
                                    <li><Button className="go" small onClick={(e) => editPost(e, item['id'])}>Submit</Button></li>
                                    <li><Button className="cancel" small onClick={(e) => noEditPost(e, index)}>Cancel</Button></li>
                                </ul>
                            </div>
                            :
                            <p className='text-post'>{item.caption}</p>
                        }
                    </div>
                    <div className='media-post'>
                        {(item['post_status_id'] === 2) ? 
                            <div>
                                <p>Processing media, it could take some minutes</p>
                                <Preloader
                                    active
                                    color="blue"
                                    flashing={true}
                                    size="medium"
                                />
                            </div>
                        : (item['post_status_id'] === 1) ? 
                            item['post_type.description'] === 'Video'
                            ?

                                <video preload="yes" 
                                       className="responsive-video"
                                       controls
                                       controlsList="nodownload"
                                       src={item['file.uri']} type="video/mp4"
                                       poster={item['file.posterFile.uri'] ? item['file.posterFile.uri'] : ''}
                                >
                                    
                                    Your browser does not support the video tag.
                                </video>
                            : item['post_type.description'] === 'Image'
                                ?
                                    <img
                                        onClick={() => renderLightBox(item['file.uri'], item.caption)}
                                        className="responsive-img"
                                        src={item['file.uri']}
                                        alt=""
                                    />
                                : item['post_type.description'] === 'Audio' ?
                                    <AudioPlayer
                                        src={item['file.uri']}
                                        volume={0.9}

                                    />
                                    : null
                            : null
                        }

                    </div>
                </div>
                <div className="action-post">
                    <ul>
                        <li>
                            <span className={item['ilike'] ? "icon liked" : "icon"} onClick={!item['ilike'] ? (e) => likePost(e, item['id']) : (e) => unlikePost(e, item['id'])}><Icon>favorite</Icon></span>
                            <span className="counting">{item.likes}</span>
                        </li>
                    </ul>
                </div>
            </div>
        )
    });
};
export default Posts;