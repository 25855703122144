import React, {Component} from 'react';
import {
	Modal, Row, TextInput, Button, Checkbox
} from 'react-materialize';
import axios from "axios";
import './style.css';

class ProfileSettings extends Component {
	constructor(props) {
        super(props);
        this.state = {
        	tab : 0,
            passwordObj : {
                current : '',
                new : '',
                renew : ''
            },
            notificationsObj : {
                post : false,
                message : false
            },
            errorPwd : null,
            successPwd : null,
            errorSettings : null,
            successSettings : null,
            validCurr: null, 
            validNew: null, 
            validRenew: null
        }
        this.getUserProfile = this.getUserProfile.bind(this);

    }
	componentDidMount() {
		
	}
    changeTab = (e, tab) => {
        e.preventDefault();
        this.setState({tab : tab});
    }
    changeHandler = (e) => {
        e.preventDefault();
        const passwordObj = this.state.passwordObj;
        const name = e.target.name;
        const value = e.target.value;
        passwordObj[name] = value;
        this.setState({passwordObj : passwordObj});
    }
    changeHandlerCheckboxNotification(e, name) {
        const notificationsObj = this.state.notificationsObj;
        if(e.target.checked)
            notificationsObj[name] = true;
        else
            notificationsObj[name] = false;
        this.setState({ notificationsObj: notificationsObj});
    }
    updatePassword = (e) => {
        e.preventDefault();
        this.setState({ errorPwd : null, successPwd : null, validCurr: null, validNew: null, validRenew: null});
        let send = true;
        const passwordObj = this.state.passwordObj;
        console.log(passwordObj.current,passwordObj.new,passwordObj.renew);
        let inputs = document.getElementsByClassName("has-error");
        for(let k = 0; k < inputs.length; k++){
            inputs[k].classList.remove("has-error");
        }
        if(!passwordObj.current){
            document.getElementById("current").classList.add("has-error");
            send = false;
            this.setState({validCurr: "It can't be empty."});            
        }
        if(!passwordObj.new || !passwordObj.new.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#^().+=_~/'"-]{6,}$/g)){
            document.getElementById("new").classList.add("has-error");
            send = false;
            this.setState({validNew: "Min. 6 characters, at least one uppercase letter, one lowercase and one number."});   
        }
        if(!passwordObj.renew){
            document.getElementById("renew").classList.add("has-error");
            send = false;
            this.setState({validRenew: "It can't be empty."});   
        }
        if(send){
            axios.post('/api/user/change_password', passwordObj).then( res => {
                if(res.data.Success){
                    this.setState({
                        successPwd : "Password has been changed successfuly"
                    })
                }
                else if(res.data.Error){
                    this.setState({
                        errorPwd : res.data.Error
                    })
                }
            });
        }
    }
    getUserProfile = async (e) => {
        
        axios.get('/api/user/get/profile_settings').then(resp=>{
            let notificationsObj = {post : resp.data.notification_post === 1 ? true : false, message : resp.data.notification_message === 1 ? true : false}
            this.setState({ notificationsObj: notificationsObj});
        });
    };
    updateSettings = (e) => {
        e.preventDefault();
        this.setState({ errorSettings : null, successSettings : null});
        let settingsObj = {notifications : this.state.notificationsObj};
        axios.post('/api/user/profile_settings', settingsObj).then(res => {
            if(res.data.Success){
                this.setState({
                    successSettings : "Profile has been updated"
                })
            }
            else if(res.data.Error){
                this.setState({
                    errorSettings : res.data.Error
                })
            }
        })
    }

	render (){
		return (
			<Modal
            options={{onOpenEnd: this.getUserProfile}}
            trigger={this.props.trigger}
			id="profileSettingsModal"
            >
				<Row>
                    <button href="#!" className="modal-close waves-effect btn-flat close-modal">
                        <span>x</span>
                    </button>
					<ul className="tab-menu tab-menu-modal">
                        <li className={(this.state.tab === 0) ? "active" : null} onClick={ (e) => this.changeTab(e,0)}>
                            <a href="#Privacy">Privacy</a>
                        </li>
                        <li className={(this.state.tab === 1) ? "active" : null} onClick={ (e) => this.changeTab(e,1)}>
                            <a href="#Settings">Profile Settings</a>
                        </li>
                    </ul> 
                    <hr/>
                    <div className="tab-container">
                        {(this.state.tab === 0) ?
                            <div>
                                <p className="tab-title">Change Password</p>
                                <TextInput
                                    onChange={(e) => this.changeHandler(e)}
                                    name="current"
                                    id="current"
                                    s={12}
                                    label='CURRENT PASSWORD'
                                    validate minLength={6}
                                    maxLength = {30}
                                    type='password'
                                >
                                </TextInput>
                                {this.state.validCurr ? 
                                    <div className="error-class error-class2">{this.state.validCurr}</div>
                                : null}
                                <TextInput
                                    onChange={(e) => this.changeHandler(e)}
                                    name="new"
                                    id="new"
                                    s={12}
                                    label='NEW PASSWORD'
                                    validate minLength={6}
                                    maxLength = {30}
                                    type='password'
                                >
                                </TextInput>
                                {this.state.validNew ? 
                                    <div className="error-class error-class2">{this.state.validNew}</div>
                                : null}
                                <TextInput
                                    onChange={(e) => this.changeHandler(e)}
                                    name="renew"
                                    id="renew"
                                    s={12}
                                    label='CONFIRM NEW PASSWORD'
                                    validate minLength={6}
                                    maxLength = {30}
                                    type='password'
                                >
                                </TextInput>
                                {this.state.validRenew ? 
                                    <div className="error-class error-class2">{this.state.validRenew}</div>
                                : null}
                                <div className="actions-modal">
                                    {this.state.successPwd ?
                                        <small className="success-message">{this.state.successPwd}</small>
                                        : null
                                    }
                                    <Button
                                        onClick={(e) => this.updatePassword(e)}
                                        waves='light'
                                    >
                                        Update Password
                                    </Button>
                                    {this.state.errorPwd ?
                                        <small className="error-message">{this.state.errorPwd}</small>
                                        : null
                                    }
                                </div>
                            </div>
                            :
                            <div>
                                <p className="tab-title">Notification Settings</p>
                                <p>Specify which notifications you want to enable/disable.</p>
                                <Checkbox value="1" label="Receive new post notifications" onChange={ (e) => this.changeHandlerCheckboxNotification(e, "post")} checked={this.state.notificationsObj.post}/>
                                <Checkbox value="1" label="Receive new message email notifications" onChange={ (e) => this.changeHandlerCheckboxNotification(e, "message")} checked={this.state.notificationsObj.message}/>
                                <div className="actions-modal">
                                    {this.state.successSettings ?
                                        <small className="success-message">{this.state.successSettings}</small>
                                        : null
                                    }
                                    <Button
                                        onClick={(e) => this.updateSettings(e)}
                                        waves='light'
                                    >
                                        Save Changes
                                    </Button>
                                    {this.state.errorSettings ?
                                        <small className="error-message">{this.state.errorSettings}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
				</Row>
	        </Modal>
		)
    };
}
export default ProfileSettings;