import React, { Component } from 'react';
import {Button, Col, TextInput} from "react-materialize";
import axios from "axios";

class ForgotPassword extends Component {
    constructor() {
        super();
        this.changeHandler = this.changeHandler.bind(this);
        this.recoverPassword = this.recoverPassword.bind(this);
        this.state = {
            email_recovery : '',
            recovery_error : null,
            recovery_success : null
        }
    }
    recoverPassword() {
        this.setState({recovery_error : null, recovery_success : null})
        const email_recovery = this.state.email_recovery;
        let send = true;
        if(!email_recovery){
            document.getElementById("email_recovery").classList.add("has-error");
            send = false;
        }
        if(send){
            axios.post('/api/user/recover_password', {mail : email_recovery}).then(res => {
                const resp = res.data;
                if(resp.Success){
                    document.getElementById("disableBtn").disabled = true;
                    document.getElementById("email_recovery").disabled = true;
                    this.setState({recovery_success : "We have send you an email with the instructions to recover your password."});
                }
                else if(resp.Error){
                    this.setState({recovery_error : resp.Error});
                }
            });
        }
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    componentDidMount() {
    }
    render() {
        return (
            <Col>
                <button className="modalCloseLogin modal-close waves-effect btn-flat close-modal">
                    <span>x</span>
                </button>
                <h3>Forgot Password</h3>
                <TextInput
                    name="email_recovery"
                    id="email_recovery"
                    s={12}
                    label='EMAIL OR USERNAME'
                    onChange={this.changeHandler}
                    validate
                    type='text'
                >
                </TextInput>
                <div className="actions-modal">
                    <Button
                        id='disableBtn'
                        onClick={this.recoverPassword}
                        waves='light'
                    >
                        GO
                    </Button>
                    {this.state.recovery_error ?
                        <small className="error-message">{this.state.recovery_error}</small>
                        : null
                    }
                    {this.state.recovery_success ?
                        <small className="success-message">{this.state.recovery_success}</small>
                        : null
                    }
                    <p className="col s12 divider-actions">
                        <hr/>
                        <span>OR</span>
                    </p>
                    <ul className="btn-group">
                        <li>
                            <a onClick={this.props.showSignup} href='#SignIn'>SIGN UP</a>
                        </li>
                        <li> | </li>
                        <li>
                            <a onClick={this.props.showLogin} href='#SignIn'>SIGN IN</a>
                        </li>
                    </ul>
                </div>
            </Col>
        );
    }
}

export default ForgotPassword;