import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';
/**
 * Navigation menu for the admin
 */
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Loader from "../../components/Loader";
import axios from 'axios';

class Approve extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            artist: 'unconfirmed',
            sub_id: 'unconfirmed',
            models : [],
            categories : [],
            user : '',
            showCatalog : false
        }
        this.openCatalog = this.openCatalog.bind(this);
        this.closeCatalog = this.closeCatalog.bind(this);
    }

    async validateSubscription(){

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let artist = params.get('artist');
        let sub_id = params.get('sub_id');
        let acc_email = params.get('acc_email');
        let tk = params.get('tk');
        let seed = params.get('seed');
        let recurring_period = params.get('recurring_period');
        let recurring_price = params.get('recurring_price');
        let price = params.get('price');

        let formData = {
            artist: artist,
            sub_id: sub_id,
            acc_email: acc_email,
            tk: tk,
            seed: seed,
            price: price,
            recurring_period: recurring_period,
            recurring_price: recurring_price
        };
        return await axios.post('/api/transaction/approved', formData)
            .then(res => {
                //console.log(res);
                this.setState({
                    sub_id: sub_id,
                    artist: artist,
                    loading: false
                });
            })
            .catch(err => {
                //window.location = "/";
                this.setState({ loading: false })
            });


    }

    componentDidMount() {

        this.validateSubscription();
        this.getCategories();
        this.getModels();
        this.setState({user : this.props.authenticated})

    }
    getModels = () => {
        axios.get('/api/home/getModels').then(res => {
            this.setState({models : res.data});
        })
    }
    getCategories = () => {
        axios.get('/api/home/getMainCategories').then(res => {
            this.setState({categories : res.data});
        })
    }
    closeCatalog = () => {
        this.setState({showCatalog : false});
    }
    openCatalog = () => {
        this.setState({showCatalog : true});
    }
    render() {
        return (this.state.loading)
            ? <Loader></Loader>
            : <div className='main main-home'>

            <Navbar
                models={this.state.models}
                categories={this.state.categories}
                user={this.state.user}
                openCatalog={this.openCatalog}
                closeCatalog={this.closeCatalog}
                showCatalog={this.state.showCatalog}/>

            <div className="container-login">

                <div className='approval-div'>
                    <p>
                        Thanks for subscribing to
                        <span><Link to={"/" + this.state.artist}> {this.state.artist}</Link></span>
                    </p>
                    <p>
                        Your subscription number is:
                        <span> {this.state.sub_id}</span>
                    </p>
                </div>
            </div>
            <div className="join-us">
                <p>Streamx handles all of the boring business stuff for our users, including credit card processing and content hosting.</p>
                <div className="join-us-main">
                    <h4>Become a StreamX talent, choose your price and make $</h4>
                    <a href="mailto:contact@streamx.tv">CONTACT</a>
                </div>
            </div>
            <Footer />

        </div>

    }

}

export default Approve