import React from 'react';
import {Icon} from 'react-materialize';
//import moment from 'moment';
//import AudioPlayer from "react-h5-audio-player";
const Grid = ({posts, openSource})=>{
    return posts.map((item, index) =>{
        if(item['post_type.description'] !== "Text"){
            let source = (item["post_type.description"] === "Image") ? item['file.uri'] : item["post_type.description"] === "Video" ? item['file.posterFile.uri'] : null;
            return (
                <div key={index}
                    className='grid-post' style={{backgroundImage: "url(" + source + ")"}}
                    onClick={(e) => openSource(e, item['file.uri'], item["post_type.description"], (item["post_type.description"] === "Video") ? item['file.posterFile.uri'] : null)}
                >
                    {item["post_type.description"] === "Video" ?
                        <Icon>play_circle_outline</Icon> :
                        item["post_type.description"] === "Audio" ? 
                            <Icon>music_note</Icon>
                            : ''

                    }
                </div>
            );
        }
    });
};
export default Grid;