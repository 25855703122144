import React, { Component } from 'react';
import {
  BrowserRouter as Router,
    Route, Switch, Redirect, withRouter
} from 'react-router-dom';
import axios from 'axios';
import Home from './containers/Home';
import Feed from './containers/Feed';
import ModelFeed from './containers/ModelFeed';
import Profile from './containers/Profile';
import Admin from './containers/Admin';
import Approve from './containers/Approve';
import Support from './containers/About/Support';
import Privacy from './containers/About/Privacy';
import Terms from './containers/About/Terms';
import FAQ from './containers/About/FAQ';
import Loader from "./components/Loader";
import UpdatePolicy from './containers/About/UpdatePolicy';
import './App.css';

var _user = false;
class App extends Component {

    constructor(){
        super();
        this.state = {
            loading: true,
            auth : false,
            userType: 3
        };
    }

    componentDidMount(){
        this.authUser();
    }

    authUser = async () => {

        return await axios.get('/api/user/authenticated')
            .then(res => {
                if (res.data) {
                    _user = res.data;
                    this.setState({loading: false, auth: true, userType: _user.user_type_id})

                }
                else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false})
            });
    }

    /**
     * Generate the admin routes
     */
    adminRoutes = ({ match }) => {

        if(this.state.auth && this.state.userType !== 1)
            return (<Redirect to="/" />);

        const AdminRouteProps = withRouter(props => <Admin {...props} />);

        return (
            <Route render={
                props => <AdminRouteProps />
            } />
        );
    };



    render() {
        if(this.state.loading) return <Loader/>;
        return (
            <Router>
                <Switch>

                    <Route exact path="/"
                        render={() => <Home authenticated={_user}/>}
                    />
                    <Route exact path="/About/Support"
                        render={() => <Support authenticated={_user}/>}
                    />
                    <Route exact path="/About/Privacy"
                        render={() => <Privacy authenticated={_user}/>}
                    />
                    <Route exact path="/About/Terms"
                        render={() => <Terms authenticated={_user}/>}
                    />
                    <Route exact path="/About/FAQ"
                        render={() => <FAQ authenticated={_user}/>}
                    />
                    <Route exact path="/About/UpdatePolicy"
                        render={() => <UpdatePolicy authenticated={_user}/>}
                    />

                    <Route path="/approve"
                        render={() => (this.state.auth)
                            ? (<Approve authenticated={_user} />)
                            : (<Redirect to="/" />)
                        }
                    />


                    <Route path="/admin" component={this.adminRoutes} />

                    <Route exact path="/:username/Feed"
                        render={props => (this.state.auth) ?
                            _user.user_type_id === 2 ?
                                <ModelFeed authenticated={_user}/>
                                :
                                <Feed authenticated={_user}/>
                            :
                            <Home authenticated={_user}/>
                        }
                    />
                    <Route exact path="/:username/Feed/Messages"
                        render={props => (this.state.auth) ?
                            _user.user_type_id === 2 ?
                                <ModelFeed authenticated={_user} displayMessages={true}/>
                                :
                                <Feed authenticated={_user} displayMessages={true}/>
                            :
                            <Home authenticated={_user}/>
                        }
                    />

                    <Route exact path="/:username"
                        render={() => <Profile authenticated={_user}/>}
                    />

                </Switch>
            </Router>
        );

    }
}

export default App;
