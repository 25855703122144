import React, { Component } from 'react';


class Thead extends Component {

    render() {

        let result = (this.props.data.length > 0)
            ? []
            : <th>Whitout Headings</th>;

        if (this.props.data.length > 0) {
            this.props.data.forEach(col => {
                result.push(<th key={col}>{col}</th>);
            });
        }

        return <thead><tr>{result}</tr></thead>;

    }

}

export default Thead