import React, { Component } from 'react';
import {Row, Icon, Button, Textarea, ProgressBar} from 'react-materialize';
import axios from 'axios';
import LogoFacebook from 'react-ionicons/lib/LogoFacebook';
//import LogoInstagram from 'react-ionicons/lib/LogoInstagram';
import LogoTwitter from 'react-ionicons/lib/LogoTwitter';
import LogoPinterest from 'react-ionicons/lib/LogoPinterest';
import MdCheckmark from 'react-ionicons/lib/MdCheckmark';
import Navbar from '../../components/Main/Navbar';
import Login from "../../components/login";
import {PhotoSwipe} from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import './style.css';
import Posts from "../../components/Profile";
import Grid from "../../components/Profile/Grid";
import Gallery from "../../components/Profile/Gallery";
import ModalOpenSource from "../../components/Profile/Grid/ModalOpenSource";
import ModalEditName from "../../components/Profile/ModalEditName";
import DeletePost from "../../components/Profile/DeletePost";
import Loader from "../../components/Loader";
import Crop from '../../components/Cropper';
import getCroppedImg from "../../components/Cropper/cropImage";
import piexif from 'piexifjs';
import AudioPlayer from "react-h5-audio-player";

class Profile extends Component {
    constructor(){
        super();
        this.state = {
            user : '',
            nameuser: '',
            profile: {},
            posts: [],
            loadingProfile: true,
            loadingPost: true,
            subscribe: false,
            modelCost: {},
            imageSrc: "",
            shapeCrop: 'round',
            aspect: 23 / 28,
            zoom: 1,
            crop: {x: 0, y: 0},
            setZoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            croppedArea: null,
            nameImputFile : null,
            openCrop : false,
            imgProfile : null,
            imgCover : null,
            publishText : "",
            uploadFile : null,
            progress : -1,
            isModel : false,
            view : 1,
            openSource : null,
            postToDelete : null,
            displayName : "",
            category : "",
            loadingWelcome : false,
            loadingWelcomeFinish : false,
            token : null,
            limit : 9,
            offset : 0,
            more: true,
            waitingPosts : [],
            isOpen: false,
            photos:[],
        }
        this.onCropChange = this.onCropChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.showCroppedImage = this.showCroppedImage.bind(this);
        this.onZoomChangeBar = this.onZoomChangeBar.bind(this);
        this.closeCrop = this.closeCrop.bind(this);
        this.startTransaction = this.startTransaction.bind(this);
        this.renderLightBox= this.renderLightBox.bind(this);

    }
    componentDidMount() {
        let user = window.location.pathname;
        const username = user.slice(1,user.length).trim();
        this.setState({nameuser : this.props.authenticated, user : username });
        this.getUserProfile(username);
        this.getUserPost(username, this.state.limit, this.state.offset, false);
        this.getToken();
        const trackScrolling = () => {
            let total = document.documentElement.scrollHeight;
            let scroll = window.scrollY + window.innerHeight;
            if(scroll >= total - 100){
                this.getUserPost(username, this.state.limit, this.state.offset, false)
            }
        };
        window.addEventListener('scroll', trackScrolling, true);
        setInterval( () => {
            let waitingPosts = [...this.state.waitingPosts];
            waitingPosts.forEach( (el) => {
                this.getPostStatus(el.id);
            })
        }, 5000);

    }
    getPostStatus = async (post) => {
        axios.get('/api/post/status/' + post).then(async resp => {
            if(resp.data.status !== 2){
                await this.getUserPost(this.state.user, this.state.limit, 0, true);
            }
        })
    }
    getToken = async () => {
        await axios.get('/api/user/getMessageKey').then(async (res) => {
            this.setState({token : res.data.token});
        })
    }
    handlePostText = async (e) => {
        this.setState({publishText : e.target.value});
    }
    closeCrop = async () => {
        this.setState({openCrop : false})
    }
    getUserProfile = async (user) => {
        const authd = this.props.authenticated;
        axios.get('/api/user/get/profile', {
            params: {
                username: user,
                authd: authd
            }
        })
            .then(resp => {
                if(resp.data.Error){
                    window.location = "/";
                }
                this.setState({
                    imgProfile : resp.data.profileImage,
                    imgCover : resp.data.coverImage,
                    profile : resp.data,
                    loadingProfile: false,
                    subscribe: resp.data.isActiveSubscriber,
                    modelCost: resp.data.subscription,
                    isModel : resp.data["isModel"],
                    displayName : resp.data["user.display_name"],
                    category : resp.data["category"]
                });
            });
    };
    getUserPost = async (user, limit, offset, reload = true) =>{
        if(this.state.more){
            let posts = [...this.state.posts];
            this.setState({loadingPost : true});
            axios.get('/api/user/get/posts', {
                params: {
                    username: user,
                    limit: limit,
                    offset : offset
                }
            }).then(resp=>{
                let newPosts = resp.data;
                if(reload){
                    let newOffset = offset + limit;
                    this.setState({offset : newOffset, posts : newPosts});
                    this.getPostsLoading(newPosts, true);
                }
                else{
                    if(newPosts.length > 0){
                        let newOffset = offset + limit;
                        this.setState({offset : newOffset, posts : posts.concat(newPosts)});
                        this.getPostsLoading(newPosts);
                    }
                    else{
                        this.setState({more : false})
                    }
                }
                this.setState({
                    loadingPost: false
                });
            });
        }
    };
    getPostsLoading = async (posts, reload = false) => {
        let waitingPosts = [];
        if(!reload){
            waitingPosts = [...this.state.waitingPosts];
        }
        await Promise.all(
            posts.map( async (el, index) => {
                if(el.post_status_id === 2){
                    waitingPosts.push({id : el.id, index : index});
                }
            })
        ).then( () => {
            this.setState({waitingPosts : waitingPosts});
        })

    }
    /**
     * To refactor on own component
     */
    drawBtnSubscribe(mobile) {
        if (this.state.isModel){
            if(!this.props.authenticated){
                return (<div className='subscribeDiv'>
                            <Login showModalBig={true} />
                            <Button
                                className='modal-trigger btn-gradient-streamx btn-featured btn-suscribe'
                                href="#loginModal">
                                Subscribe Now
                            </Button>
                        </div>);
            }
            else if(!this.state.subscribe && this.state.profile.user_id !== this.props.authenticated.user_id){
                return (
                    <form
                        data-mobile={(mobile || '')}
                        onSubmit={this.startTransaction}
                        method="GET"
                        id={(mobile || '')+"subscribe-form"} action={this.state.modelCost.ccbillFlexForm}>
                        <input
                            type="hidden"
                            name="artist"
                            value={this.state.user} />
                        <input
                            type="hidden"
                            name="tk"
                            value={this.state.modelCost.tk} />
                        <input
                            type="hidden"
                            name="user_id"
                            value={this.props.authenticated.user_id} />
                        <input
                            id={(mobile || '')+'seed'}
                            type="hidden"
                            name="seed"
                            value='' />
                        <Button
                            type="submit"
                            className='btn-gradient-streamx btn-featured btn-suscribe'>
                            Subscribe Now
                        </Button>
                    </form>
                )
            }
            else if(this.state.subscribe){
                return (
                    <Button
                        className='btn-gradient-streamx btn-featured btn-suscribe'>
                        Subscribed
                    </Button>
                )
            }
        }

    }

    async startTransaction(e){
        e.preventDefault();
        let target = e.target;
        let seedid = target.getAttribute('data-mobile') === ''
            ? 'seed'
            :'mobileseed';
        await axios.post('/api/transaction/start')
            .then(resp => {
                if(resp.status === 200){
                    document.getElementById(seedid).setAttribute('value',resp.data.seed);
                    target.submit();
                }
                else{
                    alert('something is wrong');
                    return false;
                }
            });

        return true;
    }

    removeFile = async () => {
        this.setState({uploadFile : null});
    }
    async getImage(e){
        let file =e.target.files[0];
        if(!file){
            return false;
        }
        const name = e.target.name;
        let dataURL;
        let valid = false;
        await this.setState({
            nameImputFile : name,
            zoom: 1,
            crop: { x: 0, y: 0 }
        });
        let sendCrop = false;
        let typeFormat = this.validateFormat(file.type);
        if (file.type.match('image/jpeg.*')) {
            let blob = await this.rotateImage(file);
            dataURL = await URL.createObjectURL(blob)
            file = blob;
            if(name !== "filePost"){
                sendCrop = true;
            }
            valid = true;
        }
        else if(typeFormat === "image"){
            dataURL = await URL.createObjectURL(file);
            if(name !== "filePost"){
                sendCrop = true;
            }
            valid = true;
        }
        else if(typeFormat==="video") {
            valid = true;
        }
        else if(typeFormat==="audio") {
            valid = true;

        }
        if(sendCrop){
            if (name === "profile") {
                this.setState({
                    shapeCrop: 'round',
                    aspect: 3 / 3,
                })
            }
            else if(name === "cover"){
                this.setState({
                    shapeCrop: 'square',
                    aspect: 10 / 3,
                })
            }else if(name === "welcome"){
                this.setState({
                    shapeCrop: 'square',
                    aspect: 3 / 3,
                })
            }
            await this.setState({imageSrc: dataURL})
            this.setState({openCrop : true})
        }
        else if(name === "filePost" && valid){
            this.setState({uploadFile : {file : file, type : typeFormat}})
        }
        else if(name === "welcome" && valid){
            this.uploadFile(name, file);

        }
        //img.setAttribute("src",dataURL);
        //document.getElementById("showModal").click();

    }
    validateFormat(format){
        const mediaType = format.split('/')[0];
        if(mediaType === 'video' || mediaType === 'image' || mediaType ==='audio')
            return mediaType;
        return false;
    }
    async rotateImage(file) {
        let reader = new FileReader();
        reader.onload = async (e) => {
            let exif = piexif.load(e.target.result);
            let image = new Image();
            image.onload = async () => {
                var orientation = exif["0th"][piexif.ImageIFD.Orientation];

                var canvas = document.getElementById("canvasToRotate");
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext("2d");
                var x = 0;
                var y = 0;
                ctx.save();
                if (orientation === 2) {
                    x = -canvas.width;
                    ctx.scale(-1, 1);
                } else if (orientation === 3) {
                    x = -canvas.width;
                    y = -canvas.height;
                    ctx.scale(-1, -1);
                } else if (orientation === 4) {
                    y = -canvas.height;
                    ctx.scale(1, -1);
                } else if (orientation === 5) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    y = -canvas.width;
                    ctx.scale(1, -1);
                } else if (orientation === 6) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                } else if (orientation === 7) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    x = -canvas.height;
                    ctx.scale(-1, 1);
                } else if (orientation === 8) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    x = -canvas.height;
                    y = -canvas.width;
                    ctx.scale(-1, -1);
                }
                await new Promise(resolve => {ctx.drawImage(image, x, y);});
                ctx.restore();
            };

            await new Promise( resolve => {image.src = e.target.result;});
        };
        return await new Promise( resolve => {
            reader.readAsDataURL(file);
            setTimeout(()=>{
                const canvas = document.getElementById("canvasToRotate");

                canvas.toBlob( async (blob) => {
                    return  resolve(blob)
                });
            },1200);
        });

    }
    onCropChange = crop => {
        this.setState({ crop })
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }
    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels
        );
        //const reader = new FileReader();
        let file = new File([croppedImage],this.state.nameImputFile+".png",{type: "image/png", lastModified: Date.now()});

        //file = URL.createObjectURL(file);
        this.setState({openCrop : false});
        this.uploadFile(this.state.nameImputFile, file);
    }
    onZoomChangeBar = (e, zoom)=>{
        e.preventDefault();
        this.setState({ zoom:e.target.value })
    }
    uploadFile = async (fileName, file) => {
        if(fileName === "welcome"){
            this.setState({loadingWelcome : true});
        }
        const formData = new FormData();
        formData.append("file", file, fileName);
        formData.append('auth',this.props.authenticated.user_id);
        formData.append('username', this.state.user);
        formData.append('fileName', this.state.token);
        await axios.post('/api/file/profile/upload', formData).then( async (resp) => {
            if(fileName === "profile"){
                this.setState({imgProfile: resp.data.file});
            }
            else if(fileName === "cover"){
                this.setState({imgCover: resp.data.file});
            }
            else if(fileName === "welcome"){
                this.setState({loadingWelcome : false, loadingWelcomeFinish : true});
                setTimeout( () => {
                    this.setState({loadingWelcomeFinish : false});
                }, 5000);
            }
        })

    }
    uploadMediaPost = async (post) => {
        const formData = new FormData();
        const file = this.state.uploadFile.file;
        formData.append("file", file, "post");
        formData.append('auth',this.props.authenticated.user_id);
        formData.append('post', post);
        formData.append('username', this.state.user);
        formData.append('fileName', this.state.token);
        axios.post('/api/file/profile/upload', formData, {
            onUploadProgress: progressEvent =>
            {
                let progress= Math.floor((progressEvent.loaded / progressEvent.total)*100);
                this.setState({progress : progress});
                if(progress === 100){
                    setTimeout( () => {
                        this.setState({uploadFile : null, progress : -1})
                        this.getUserPost(this.state.user, (1+this.state.offset), 0);
                    }, 1000);
                }
            }
        });
        return true;
    }
    post = async (e) => {
        const text = document.getElementById("postText").value;
        let file = false;
        let type = null;
        if(this.state.uploadFile){
            file = true;
            type = this.state.uploadFile.type;
            this.setState({progress : 1});
        }
        axios.post('/api/post',{post : text, hasFile : file, type : type}).then( async (res) => {
            if(res.data.Success){
                this.setState({publishText : ""});
                document.getElementById("postText").value="";
                if(file){
                    await this.uploadMediaPost(res.data.post)

                }
                else{
                    this.getUserPost(this.state.user, (1+this.state.offset), 0);
                }
            }
        })
    }
    changeView = async (e, view) => {
        this.setState({view : view});
    }
    openSource = async (e, src, type, poster = null) =>{
        this.setState({openSource : {src : src, type : type, poster : poster}});
    }
    closeSource = async (e) => {
        this.setState({openSource : null});
    }
    handleClose = (e) => {
        this.setState({isOpen : false});
    }
    async renderLightBox(file, caption){
        var imageArr = [];
        const img = new Image();
        img.src=file;
        const image={
            src:img.src,
            w:img.width,
            h:img.height,
            title:caption,
        }
        imageArr.push(image);
        await this.setState({
           photos: imageArr,
           isOpen: true,
           options: {
                index:0,
                closeOnScroll: false,
                shareEl: false
           }
        });
    }
    showSubmenu = async (e) => {
        const menu = e.target.parentElement.nextSibling;
        if(menu.classList.contains("open")){
            menu.classList.remove("open");
        }
        else{
            menu.classList.add("open");
        }
    }
    deletePost = async (e) => {
        axios.put("/api/post/delete", {post : this.state.postToDelete}).then( async (resp) => {
            if(resp.data.Success){
                this.cancelDelete(e);
                this.getUserPost(this.state.user, (this.state.offset - 1), 0);
            }
        })
    }
    toDeletePost = async (e, post) => {
        this.setState({postToDelete : post});
        e.target.parentElement.classList.remove("open")
    }
    toEditPost = async (e, index) => {
        const posts = [...this.state.posts];
        posts[index]['edit'] = true;
        this.setState({posts : posts});
        e.target.parentElement.classList.remove("open")
    }
    noEditPost = async (e,index) => {
        const posts = [...this.state.posts];
        posts[index]['edit'] = null;
        this.setState({posts : posts});
    }
    editPost = async (e, post) => {
        const text = e.target.closest("ul").previousSibling.children[0].value;
        axios.put("/api/post/edit", {post : post, text : text}).then( async (resp) => {
            if(resp.data.Success){
                this.getUserPost(this.state.user, this.state.offset, 0);
            }
        })
    }
    cancelDelete = async (e) => {
        this.setState({postToDelete : null})
    }
    likePost = async (e, post) => {
        axios.post("/api/post/like", {post : post}).then( async (resp) => {
            if(resp.data.Success){
                this.getUserPost(this.state.user, this.state.offset, 0);
            }
        });
    }
    unlikePost = async (e, post) => {
        axios.post("/api/post/unlike", {post : post}).then( async (resp) => {
            if(resp.data.Success){
                this.getUserPost(this.state.user, this.state.offset,  0);
            }
        });
    }
    editNameModal = async (e, name) => {
        axios.put("/api/user/update/display_name", {name : name}).then( async (resp) => {
            if(resp.data.Success){
                this.setState({displayName : name})
            }
        });
    }
    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    openMessage = async (e) => {
        window.location = "/" + this.state.user + "/Feed/Messages"
    }

    renderMain(){
        if(this.state.loadingProfile && this.state.loadingProfile)
            return <Loader/>;
        return(
            <div className="container main-container-post">
                <canvas id="canvasToRotate"></canvas>
                {this.state.openSource ?
                    <ModalOpenSource source={this.state.openSource} closeSource={this.closeSource}/>
                    : null
                }
                {this.state.postToDelete ?
                    <DeletePost post={this.state.postToDelete} cancelDelete={this.cancelDelete} deletePost={this.deletePost}/>
                    : null
                }
                <Row className='profile'>

                    <div className="profile-head-sticky">
                        <img className='img-profile' src={this.state.imgProfile} alt=""/>
                        {(this.state.profile.user_id === this.props.authenticated.user_id) ?
                            <>
                                <Icon className="camera-profile">camera_alt</Icon>
                                <input className="change-image" accept=".jpg,.jpeg,.png" type="file" name="profile" onChange={(e) => this.getImage(e)}/>
                                <div className="change-image">
                                    <Icon className="icon-change">camera_alt</Icon>
                                </div>
                            </>
                            :
                            null
                        }
                        {this.drawBtnSubscribe()}
                    </div>
                    <div className='main-profile'>
                        <div className="profile-head">
                            <img className='img-profile' src={this.state.imgProfile} alt=""/>
                            {(this.state.profile.user_id === this.props.authenticated.user_id) ?
                                <>
                                    <Icon className="camera-profile">camera_alt</Icon>
                                    <input className="change-image" accept=".jpg,.jpeg,.png" type="file" name="profile" onChange={(e) => this.getImage(e)}/>
                                    <div className="change-image">
                                        <Icon className="icon-change">camera_alt</Icon>
                                    </div>
                                </>
                                :
                                null
                            }
                            {this.drawBtnSubscribe('mobile')}
                        </div>
                        <div className="cover" style={{backgroundImage : "url(" + this.state.imgCover + ")"}}>
                        {(this.state.profile.user_id === this.props.authenticated.user_id) ?
                            <Icon className="camera-cover">camera_alt</Icon>
                            : null
                        }
                        </div>
                        {(this.state.profile.user_id === this.props.authenticated.user_id) ?
                            <input className="change-cover" accept=".jpg,.jpeg,.png" type="file" name="cover" onChange={(e) => this.getImage(e) }/>
                            :
                            null
                        }
                        <hr />
                        <div className="profile-content">
                            {(this.state.profile.user_id === this.props.authenticated.user_id) ?
                                <ModalEditName trigger={
                                    <span><Icon className='edit-profile'>create</Icon></span>
                                }
                                    displayName={this.state.displayName}
                                    editNameModal={this.editNameModal}
                                />
                                :
                                null
                            }
                            <h3>
                                {this.state.displayName}
                            </h3>
                            { this.state.isModel ?
                                <p className="category">{this.state.category}</p>
                                : null}

                            { this.state.isModel ?
                                <p>Updated Policy</p>
                                : null}
                            { (this.state.profile.user_id !== this.props.authenticated.user_id && this.state.isModel) ?
                                <ul className='subs-benefits'>
                                    <li>
                                        <h5>Subscription Benefits</h5>
                                    </li>
                                    <li>
                                        <MdCheckmark color='#f00b93'/> 3 Photos/Videos per week.
                                    </li>
                                    <li>
                                        <MdCheckmark color='#f00b93'/> Full access to user's profile content.
                                    </li>
                                    <li>
                                        <MdCheckmark color='#f00b93'/> You can directly message the profile owner.
                                    </li>
                                    <li>
                                        <MdCheckmark color='#f00b93'/> With Streamx, you can cancel anytime.
                                    </li>
                                </ul>
                                : null }
                            {
                                (this.props.authenticated && this.state.subscribe && this.state.profile.user_id !== this.props.authenticated.user_id) ?
                                <Button
                                    className=' btn-gradient-streamx btn-featured btn-open-messages'
                                    onClick={(e) => this.openMessage(e)}>
                                    <Icon left>
                                        message
                                    </Icon>
                                    Send Message
                                </Button>
                                : null
                            }
                            <h4 className='share-profile'>SHARE PROFILE</h4>
                            <ul className='list-social-app'>

                                <li className="share-fb">
                                    <a href={"https://www.facebook.com/sharer/sharer.php?u=https://streamx.tv/"+this.state.user}>
                                        <LogoFacebook fontSize="27px" color="#eee" />
                                    </a>
                                </li>
                                <li className="share-tw">
                                    <a href={"https://twitter.com/intent/tweet?status="+this.state.user+"+https://streamx.tv/"+this.state.user}>
                                        <LogoTwitter fontSize="27px" color="#eee" />
                                    </a>
                                </li>
                                <li className="share-pt">
                                    <a href={"https://pinterest.com/pin/create/button/?url=https://streamx.tv/"+this.state.user+"&media="+this.state.imgProfile+"&description="+this.state.user}>
                                        <LogoPinterest fontSize="27px" color="#eee"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div  className="profile-gallery">
                        <div className="cover" style={{backgroundImage : "url(" + this.state.imgCover + ")"}}>

                        </div>
                        {(this.state.profile.user_id === this.props.authenticated.user_id) && this.state.isModel ?
                            <ul className="welcome-actions">
                                <li>
                                    <Button>Add Welcome Video</Button>
                                    <input name="welcome" type="file" accept=".mp4,.wmv,.avi" onChange={(e) => this.getImage(e)} />
                                </li>
                                <li>
                                { this.state.loadingWelcome ?
                                    <div className="preloader-wrapper small active">
                                        <div className="spinner-layer spinner-red-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.state.loadingWelcomeFinish ?
                                        <span className="done"><Icon>done</Icon></span>
                                        :
                                        <span>OR</span>
                                }
                                </li>
                                <li>
                                    <Button>Add Welcome Image</Button>
                                    <input name="welcome" type="file" accept=".jpg,.jpeg,.png" onChange={(e) => this.getImage(e)} />
                                </li>
                            </ul>
                            : null
                        }
                        {(this.state.profile.user_id === this.props.authenticated.user_id) ?
                            <>
                                <input className="change-cover" accept=".jpg,.jpeg,.png" type="file"  name="cover" onChange={(e) => this.getImage(e)} />
                                <div className="change-cover">
                                    <Icon className="icon-change">camera_alt</Icon>
                                </div>
                            </>
                            :
                            null
                        }
                        {(this.state.profile.userId === this.props.authenticated.user_id) ?
                            <div className="publish">
                                <img alt='imgProf' src={this.state.imgProfile} />
                                <Textarea s={12} placeholder="Write your thought..." id="postText"/>
                                <div className="publish-foot">
                                    <ul>
                                        <li><Icon>camera_alt</Icon>
                                            {!this.state.uploadFile ?
                                                <input name="filePost" type="file" accept=".jpg,.jpeg,.png" onChange={(e) => this.getImage(e)}/>
                                                :
                                                null
                                            }
                                        </li>
                                        <li><Icon>videocam</Icon>
                                            {!this.state.uploadFile ?
                                                <input name="filePost" type="file" accept=".mp4,.wmv,.avi" onChange={(e) => this.getImage(e)}/>
                                                :
                                                null
                                            }
                                        </li>
                                        <li><Icon>headset</Icon>
                                            {!this.state.uploadFile ?
                                                <input name="filePost" type="file" accept=".mp3,.wav" onChange={(e) => this.getImage(e)}/>
                                                :
                                                null
                                            }
                                        </li>
                                    </ul>
                                    <Button
                                        className='btn-post btn-feature' onClick={(e) => this.post(e)}>
                                        Post
                                    </Button>
                                    {this.state.uploadFile ?
                                        <div className="publish-media">
                                            <span className="removeFile" onClick={(e) => this.removeFile(e)}><Icon>close</Icon></span>
                                            { this.state.uploadFile.type === "image" ?
                                                <img alt='uploadF' src={URL.createObjectURL(this.state.uploadFile.file)}/>
                                                :
                                                this.state.uploadFile.type === "video" ?
                                                    <video preload="yes"
                                                       className=" responsive-video" controls controlsList="nodownload">
                                                        <source
                                                            src={URL.createObjectURL(this.state.uploadFile.file)}
                                                            type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                    </video>
                                                    :
                                                    <AudioPlayer
                                                        src={URL.createObjectURL(this.state.uploadFile.file)}
                                                        volume={0.4}

                                                    />
                                            }
                                            {
                                                this.state.progress >= 0 ?
                                                    <ProgressBar className='uploadProgressBar' progress={parseFloat(this.state.progress)}/>
                                                    : null
                                            }

                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                        { this.props.authenticated && ((this.state.profile.user_id === this.props.authenticated.user_id) || this.state.subscribe) ?
                            <ul className="show-options">
                                <li onClick={(e) => this.changeView(e, 1)} className={(this.state.view === 1) ? 'active' : ''}>
                                    <ul>
                                        <li>
                                            <Icon>list</Icon>
                                        </li>
                                        <li>
                                            List View
                                        </li>
                                    </ul>
                                </li>
                                <li onClick={(e) => this.changeView(e, 2)} className={(this.state.view === 2) ? 'active' : ''}>
                                    <ul>
                                        <li>
                                            <Icon>view_module</Icon>
                                        </li>
                                        <li>
                                            Grid View
                                        </li>
                                    </ul>

                                </li>
                            </ul>
                            : null
                        }
                        {((this.state.profile.user_id === this.props.authenticated.user_id) || this.state.subscribe) && this.props.authenticated
                            ?
                                this.state.posts.length>1
                                ?
                                    this.state.view === 1 ?
                                        <Posts
                                            posts={this.state.posts}
                                            imgProfile={this.state.imgProfile}
                                            username={this.state.user}
                                            owner={this.state.profile.user_id === this.props.authenticated.user_id}
                                            showSubmenu={this.showSubmenu}
                                            toDeletePost={this.toDeletePost}
                                            toEditPost={this.toEditPost}
                                            noEditPost={this.noEditPost}
                                            editPost={this.editPost}
                                            likePost={this.likePost}
                                            unlikePost={this.unlikePost}
                                            renderLightBox={this.renderLightBox}/>
                                        :
                                        <div className="grid-content">
                                            <Grid
                                                openSource={this.openSource}
                                                posts={this.state.posts}
                                            />
                                        </div>
                                :
                                    !this.state.subscribe
                                    ?
                                    <div className='center'>
                                        <h5>User currently has nothing</h5>
                                        <h5>posted to his/her profile</h5>
                                    </div>
                                    :
                                    <div className='center'>

                                    </div>
                            :
                            <Gallery
                                user={this.state.profile.user_id}
                                authenticated={this.props.authenticated}
                                modelCost={this.state.modelCost}
                            />
                        }
                    </div>
                </Row>
            </div>
        )
    }
    render() {
        return (
            <div className='main-p'>
                <Navbar user={this.state.nameuser}/>
                {this.renderMain()}
                <Crop
                    showCroppedImage={this.showCroppedImage}
                    onZoomChangeBar={this.onZoomChangeBar}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    imageSrc={this.state.imageSrc}
                    shapeCrop={this.state.shapeCrop}
                    aspect={this.state.aspect}
                    zoom={this.state.zoom}
                    crop={this.state.crop}
                    croppedAreaPixels={this.state.croppedAreaPixels}
                    croppedImage={this.state.croppedImage}
                    croppedArea={this.state.croppedArea}
                    closeCrop={this.closeCrop}
                    openCrop={this.state.openCrop}
                />
                <PhotoSwipe isOpen={this.state.isOpen} items={this.state.photos} options={this.state.options} onClose={(e) => this.handleClose(e)} />

            </div>
        );
    }
}

export default Profile;