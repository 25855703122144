import React, { Component } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import axios from "axios";
import './style.css';

class Home extends Component {
    constructor(){
        super();
        this.state = {
            models : [],
            categories : [],
            user : '',
            showCatalog : false
        }
        this.openCatalog = this.openCatalog.bind(this);
        this.closeCatalog = this.closeCatalog.bind(this);
    }
    componentDidMount() {
        this.getCategories();
        this.getModels();
        this.setState({user : this.props.authenticated})
    }
    getModels = () => {
        axios.get('/api/home/getModels').then(res => {
            this.setState({models : res.data});
        })
    }
    getCategories = () => {
        axios.get('/api/home/getMainCategories').then(res => {
            this.setState({categories : res.data});
        })
    }
    closeCatalog = () => {
        this.setState({showCatalog : false});
    }
    openCatalog = () => {
        this.setState({showCatalog : true});
    }
    render() {
        return (
            <div className='main main-home'>

            	<Navbar
                    models={this.state.models}
                    categories={this.state.categories}
                    user={this.state.user}
                    openCatalog={this.openCatalog}
                    closeCatalog={this.closeCatalog}
                    showCatalog={this.state.showCatalog}/>

            	<div className="container-login">

                    <div className='center-div'>
                        <img
                            className='img-responsive'
                            src='https://storage.googleapis.com/streamx-251917.appspot.com/public/img/logo-new.png' alt='logo' />

                        <button
                            className='btn-featured'
                            onClick={ (e) => this.openCatalog(e) }>
                            Featured Streamx Models
                        </button>
                        {!this.state.user ?
                            <button
                                className="btn-featured btn-login-home modal-trigger"
                                href="#loginModal"
                                waves='light' >
                                Login / Sign Up
                            </button>
                            : null 
                        }
                    </div>
                </div>
                <div className="join-us">
                    <p>Streamx handles all of the boring business stuff for our users, including credit card processing and content hosting.</p>
                    <div className="join-us-main">
                        <h4>Become a StreamX talent, choose your price and make $</h4>
                        <a href="mailto:contact@streamx.tv">CONTACT</a>
                    </div>
                </div>
                <Footer />

            </div>
        );
    }
}

export default Home;