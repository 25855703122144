import React, { Component } from 'react';
import SearchFormTable from '../SearchFormTable'
import AddSubscriptionForm from './AddSubscriptionForm'
import Table from '../../Table';

import {
    Container, Col, Row
} from 'react-materialize';


class Subscription extends Component {
    constructor(props) {

        super(props);
        this.state = {
            dataTable: '',
            resultAddSubs: ''
        };

        this.updateDataTable = this.updateDataTable.bind(this);
        this.updateResultAddSubscription = this.updateResultAddSubscription.bind(this);

    }

    updateResultAddSubscription(data) {
        console.log(data);
        this.setState({ resultAddSubs: data});
    }

    /**
     * Set the new data for Datatable
     * @param [{}, {}, ...]
     */
    updateDataTable(data){
        this.setState({
            dataTable: data
        });
    }

    render() {

        let tableOptions = {
            uri: "/api/admin/subscriptions" ,
        };

        return <Container className="admin-container">
            <Row>
                <Col className="admin-box">
                    <h5>Add Subscription</h5>
                    <AddSubscriptionForm
                        onSearch={this.updateResultAddSubscription} />
                    <div>
                        {this.state.resultAddSubs}
                    </div>

                </Col>

            </Row>
            <Row>
                <Col s={12} className="admin-box">
                    <h5>Find Subscription</h5>
                    <SearchFormTable onSearch={this.updateDataTable} options={tableOptions}/>
                    <Table
                        id="table-subscriptions"
                        data={this.state.dataTable}
                        options={tableOptions}/>
                </Col>
            </Row>
        </Container>;
    }

}

export default Subscription;