import React, { Component } from 'react';

import Table from '../../Table';

import {
    Container, Col, Row
} from 'react-materialize';


class Dashboard extends Component {
    /*
    constructor(props) {

        super(props);

    }
    */

    /**
     * Validate whats be mount
     */
    componentDidMount() {

    }

    render() {

        return <Container className="admin-container">
                <Row>
                    <Col className="admin-box" s={12} xl={6}>
                        <h5>Weekly registers</h5>
                        <Table
                            id="table-weekly-users"
                            options={{ uri: "/api/admin/weekly-users" }} />
                    </Col>
                    <Col className="admin-box" s={12} xl={6}>
                        <h5>Weekly posts</h5>
                        <Table
                            id="table-weekly-posts"
                            options={{ uri: "/api/admin/weekly-posts" }} />
                    </Col>
                </Row>
                <Row>
                    <Col className="admin-box" s={12} xl={6}>
                        <h5>Weekly subscriptions</h5>
                    <Table
                        id="table-weekly-subscriptions"
                        options={{ uri: "/api/admin/weekly-subscriptions" }} />
                    </Col>
                    <Col className="admin-box" s={12} xl={6}>
                        <h5>Weekly subscriptions cancelations</h5>
                        <Table
                            id="table-weekly-subscription-cancelations"
                            options={{
                                uri: "/api/admin/weekly-subscription-cancelations"
                            }} />
                    </Col>
                </Row>
            </Container>;
    }

}

export default Dashboard;