import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Button, Row, Col} from "react-materialize";
import './style.css';
import Promise from 'bluebird';

class Catalog extends Component {
    constructor(){
        super();
        this.state = {
            modelsView : [],
            category : 0,
            search : ""
        }
        this.hidePanel = this.hidePanel.bind(this);
    }
    componentDidMount() {
        this.searchModel();
    }
    hidePanel = async (e) => {
        document.getElementById("catalog").classList.add("fadeout");
        await setTimeout( () => {
            this.props.closeCatalog(e);
        },300);
    }
    renderCatalog = () => {
        const modelos = [...this.state.modelsView];
        return modelos.map((item,index) => {
            let uri = '/' + item["user.username"];
            let file = item["user.user_profile.profile_galleries.file.uri"] ? item["user.user_profile.profile_galleries.file.uri"] : "https://storage.googleapis.com/streamx-251917.appspot.com/public/user.png"
            return (
                <Col key={index} s={12} m={6} l={4} xl={3}>
                    <div className="item-result">
                            <Link to={uri} className="image-result">
                                <img src={file} alt={item["user.display_name"]}/>
                            </Link>   
                        <h5><Link to={uri}>{item["user.display_name"]}</Link></h5>
                        <p>{item["model_category.description"]}</p>
                        <Button
                            className="btnA"
                            href={uri}
                            node="a"
                            waves='light'>
                            View Profile
                        </Button>
                    </div>
                </Col>
            )
        });
    }
    renderCategories = () => {
        const categories = [...this.props.categories];
        return categories.map( (item, index) => {
            return (
                <span key={index} className={this.state.category === item.id ? "active" : ""} onClick={ (e) => this.handleCategoryChange(e, item.id)}>
                    {item.description}
                </span>
            )
        })
    }
    handleCategoryChange = async (e, value) => {
        await this.setState({category : value})
        this.searchModel()
    }
    handleSearchChange = async (e) => {
        await this.setState({search : e.target.value})
        this.searchModel()
    }
    searchModel = async () => {
        const category = this.state.category;
        const search = this.state.search;
        const models = [...this.props.models];
        let modelsView = [];
        await Promise.map(models, (item, index) => {
            if( (item["model_category.id"] === category || category === 0) && ( item["user.display_name"].includes(search) || search === "" )){
                modelsView.push(item);
            }
        })
        this.setState({modelsView : modelsView})
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.models.length > 0 || nextState.modelsView.length > 0){
            return true;
        }
        return false
    }

    render() {
        return (
            <div className='catalog' id="catalog">
            	<div className="catalog-head">
                    <img className='img-responsive' src='https://storage.googleapis.com/streamx-251917.appspot.com/public/img/logo-new.png' alt='logo' />
                    <div className="center-search input-field">
                        {!this.props.user ?
                            <button className="z-depth-4 login-btn modal-trigger" waves='light' href="#loginModal">Login</button>
                        :
                            <button className="z-depth-4 login-btn user-button" waves='light' onClick={(e) => {document.getElementById("show-drop-menu").click()}}>
                                <span className="image" style={{backgroundImage : "url(" + this.props.user["user_profile.file.uri"] + ")" }}></span>
                                <span className="text">{this.props.user.username}</span>
                            </button>
                        }
                        <input id="search" type="search" className="" required placeholder="your favorite artist" onKeyUp={ (e) => this.handleSearchChange(e) }/>
                        <label className="label-icon" htmlFor="search">SEARCH</label>
                        <span className="close" onClick={(e) => this.hidePanel(e)}>X</span>
                    </div>
                </div>
                <div className="catalog-body">
                    <h1>Popular Categories</h1>
                    <div className="categories">
                        <span className={this.state.category === 0 ? "active" : ""} onClick={ (e) => this.handleCategoryChange(e, 0)}>
                            All
                        </span>
                        {this.renderCategories()}
                    </div>
                    <p className="result-legend">Search result <span>({this.state.modelsView.length})</span></p>
                    <div className="results">
                        <Row>
                            {this.renderCatalog()}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default Catalog;