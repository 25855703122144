import React, { Component } from 'react';
import {Col, Row, Button, TextInput, Textarea,Select} from 'react-materialize';
import axios from 'axios';
import Navbar from '../../../components/Main/Navbar';
import './style.css';

class Support extends Component {
    constructor(){
        super();
        this.state = {
            user : '',
            email: '',
            models: [],
            textarea: '',
            nombreError: false,
            emailError: false,
            selectError: false,
            subjectError: false,
            messageError: false
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount() {
        if(this.props.authenticated !== null)
        {
        this.setState({user : this.props.authenticated.username,email: this.props.authenticated.email});
        }
        this.getModels();
    }
    changeHandler(e){
        this.setState({ [e.target.name]: e.target.value});
    }
    getModels = () => {
        axios.get('/api/support/getModels').then(res => {
            this.setState({models : res.data});
        })
    }
    sendSupport=async()=>{
        let error;
        await this.setState({
            nombreError:false, 
            emailError: false, 
            selectError:false, 
            subjectError: false, 
            messageError: false,
            SuccesMensage: false
        });
        const username    = document.getElementById('username').value;
        const mail        = document.getElementById('mail').value;
        const selectmodel = document.getElementById('selectmodel').value;
        const subject     = document.getElementById('subject').value;
        const message     = document.getElementById('message').value;
        if (username.length <= 0){
            error=true;
            await this.setState({nombreError: "Error"});
        }
        if (mail.length < 3 || !mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/igm)){
            error=true;
            await this.setState({emailError: "Error, invalid email"});
        }
        if (selectmodel < 1){
            error=true;
            await this.setState({selectError: "Select a valid model."});
        }
        if (subject.length < 2){
            error=true;
            await this.setState({subjectError: "Error, min 2 char."});
        }
        if (message.length < 10){
            error=true;
            await this.setState({messageError: "Error, min 10 char."});
        }
        if(error) return;
        const supportData={
            name: username,
            email: mail,
            selectmodel: selectmodel,
            subject: subject,
            message: message 
        }
        axios.post('/api/support/sendSupport', supportData).then(res => {
            this.setState({SuccesMensage: "Thank you for contacting the support team! We will check your message as soon as possible."});
            console.log(res.data);
        });
        
    }
    renderObject(){
        if (!this.state.models.length) return;
        const modelNames= [...this.state.models];
        let items = [];
        items.push(<option key='-1' value='0'>Select</option>)
        modelNames.map((item, index)=>{
            return items.push(
                <option key={index} value={item.id}>{item["user.username"]}</option>
            )
        });
        return items;
    }
    render() {
        return (
            <div className='main-p'>
                <Navbar user={this.state.user} />
                <div className="container main-container-post">
                    <div className="message-container ">
                        <h5 className='center-align'>
                            <i className="material-icons">
                                help
                            </i>
                        </h5>
                        <h5 className='center-align'>Contact support:</h5>
                        <Row className='row supportf'>
                            <Col m={6} s={12}>
                                {this.state.user ?
                                    <TextInput id="username" name="username" maxLength="60" label="Username" value={this.state.user} onChange={this.changeHandler}/>
                                : 
                                    <TextInput id="username" name="username" maxLength="60" label="Username" onChange={this.changeHandler}/>
                                }
                                {this.state.nombreError ? 
                                    <div className="error-class">{this.state.nombreError}</div>
                                : null}
                            </Col>
                            <Col m={6} s={12}>
                                {this.state.email ?
                                    <TextInput id="mail" name="mail" maxLength="120" label="Email" value={this.state.email} onChange={this.changeHandler}/>
                                : 
                                    <TextInput id="mail" name="mail" maxLength="120" label="Email" onChange={this.changeHandler}/>
                                }
                                {this.state.emailError ? 
                                    <div className="error-class">{this.state.emailError}</div>
                                : null}
                            </Col>
                            <Col m={6} s={12}>
                                <Select
                                  label="Specify Model"
                                  options={{
                                    classes: '',
                                    dropdownOptions: {
                                      alignment: 'left',
                                      inDuration: 150,
                                      onCloseEnd: null,
                                      onCloseStart: null,
                                      onOpenEnd: null,
                                      onOpenStart: null,
                                      outDuration: 250
                                    }
                                  }}
                                  id="selectmodel"
                                  name="selectmodel"
                                  defaultValue='0'
                                  onChange={this.changeHandler}
                                >
                                {this.renderObject()}
                                </Select>
                                {this.state.selectError ? 
                                    <div className="error-class">{this.state.selectError}</div>
                                : null}
                            </Col>
                            <Col m={6} s={12}>
                                <TextInput id="subject" name="subject" maxLength="60" label="Subject" onChange={this.changeHandler}/>
                                {this.state.subjectError ? 
                                    <div className="error-class">{this.state.subjectError}</div>
                                : null}
                            </Col>
                            <Col m={12} s={12}>
                                <Textarea id="message" name="message" s={12} label="Your message" placeholder="max. 300 characters" maxLength="300" onChange={this.changeHandler}/>
                                {this.state.messageError ? 
                                    <div className="error-class">{this.state.messageError}</div>
                                : null}
                            </Col>
                            <Col m={12} s={12} className='center-align'>
                                <Button className='btn-send btn-featured' onClick={this.sendSupport}>
                                    Send
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default Support;