import React, { Component } from 'react';
import {
    Col, Row, TextInput, DatePicker, Button, Icon
} from 'react-materialize';
import axios from "axios";


class SearchFormTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            from: "",
            to: ""
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }

    changeHandler(e){

        let target = e.target;
        let value = target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });

    }

    setDate(date, name){

        let value = (date instanceof Date)
            ? date.toISOString().substr(0, 10)
            : ' ';
        this.setState({
            [name]: value
        });

    }

    async submitHandler(e){
        e.preventDefault();
        return await axios.get(this.props.options.uri, {
            params: this.state
        })
            .then(res => {
                if(res)
                    this.props.onSearch(res.data);

            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {

        const options = {
            autoClose: true,
            format: "yyyy-mm-dd",
        };

        return <Row>
            <Col>
                <form onSubmit={this.submitHandler}>
                    <TextInput
                        name="search"
                        onChange={this.changeHandler}
                        value={this.state.search}
                        label="Term to search"
                    />
                    <DatePicker
                        id="from"
                        options={options}
                        name="from"
                        onChange={(date) => { this.setDate(date, 'from'); }}
                        value={this.state.from}
                        label='From'
                    />
                    <DatePicker
                        id="to"
                        options={options}
                        name="to"
                        onChange={(date) => { this.setDate(date, 'to'); }}
                        value={this.state.to}
                        label='To'
                    />
                    <Button className="button-admin"
                        small
                        type="submit"
                        waves="light">
                            Filter
                            <Icon right>
                                send
                            </Icon>
                    </Button>
                </form>
            </Col>
        </Row>;
    }

}

export default SearchFormTable;