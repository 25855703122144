import React, { Component } from 'react';

import Table from '../../Table';

import {
   Container, Col, Row
} from 'react-materialize';


class Model extends Component {

    /*
    constructor(props) {

        super(props);

    }
*/

    /**
     * Validate whats be mount
     */
    componentDidMount() {

    }

    render() {

        return <Container className="admin-container">
            <Row>
                <Col s={12} className="admin-box">
                    <h5 >Posts</h5>
                    <Table
                        id="table-posts"
                        options={{ uri: "/api/admin/weekly-posts" }} />
                </Col>
            </Row>
        </Container>;
    }

}

export default Model;