import React, { Component } from 'react';
import Navbar from '../../../components/Main/Navbar';
import './style.css';

class FAQ extends Component {
    constructor(){
        super();
        this.state = {
            user : ''
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount() {
        if(this.props.authenticated !== null)
        {
        this.setState({user : this.props.authenticated.username,email: this.props.authenticated.email});
        }
    }
    changeHandler(e){
        this.setState({ [e.target.name]: e.target.value});
    }
    
    render() {
        return (
            <div className='main-p'>                
                <Navbar user={this.state.user} />
                <div className="container main-container-privacy">
                    <div className="privacy-container ">
                        <h4 className='center-align' >
                            Frequently Asked Question
                        </h4>
                        <div>
                            <h6>
                                1. How do I cancel my subscription?
                            </h6>
                            <p>
                                You can cancel your subscription anytime from <a className='urlPrivacy' href="https://Support.ccbill.com">Support.ccbill.com</a>
                            </p>
                        </div>
                        <div>
                            <h6>
                                2. How do I cancel my account?
                            </h6>
                            <p>
                                You can cancel your account anytime by accessing your profile settings.
                            </p>
                        </div>
                        <div>
                            <h6>
                                3. What will show in my billing statement?
                            </h6>
                            <p>
                                The billing statement in the email contain the Name, Email, Order number, Order amount,Order description, username.
                            </p>
                        </div>
                        <div>
                            <h6>4. 
                                How do I change my password?
                            </h6>
                            <p>
                                You can change password by click on the profile setting option. There is privacy option available for change your password.
                            </p>
                        </div>
                        <div>
                            <h6>
                                5. How much does stream X Cost?
                            </h6>
                            <p>
                                Stream X profiles owners set their own prices for monthly memberships.
                            </p>
                        </div>
                        <div>
                            <h6>
                                6. How can I pay for Stream X?
                            </h6>
                            <p>
                                Credit and Debit Cards
                                <ul>
                                    <li>
                                        We accept Visa, MasterCard, and American Express cards.
                                    </li>
                                    <li>
                                        Cards must be enabled for recurring e-commerce transactions to work with Stream X.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <h6>
                                7. Can I pay Stream X with cash?
                            </h6>
                            <p>
                                As of now stream x does not accept cash payments.
                            </p>
                        </div>
                        <div>
                            <h6>
                                8. What if I am having trouble with technical stuff... who can I contact at streamx.tv?
                            </h6>
                            <p>                            
                                Send us an email anytime! support@streamx.tv
                            </p>
                        </div>
                    </div>
                    <div className='copyright'>
                        &#169; Copyright streamx, 2019 All right reserved.
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQ;