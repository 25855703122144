import React, { Component } from 'react';
import {Button, Checkbox, Col, Row, TextInput} from "react-materialize";
import axios from "axios";

class Register extends Component {
    constructor(){
        super();
        this.changeHandlerRegister = this.changeHandlerRegister.bind(this);
        this.changeHandlerRegisterCheckbox = this.changeHandlerRegisterCheckbox.bind(this);
        this.signup = this.signup.bind(this);
        this.state = {
            registerObj : {},
            errorRegister : null,
            usernameError:false,
            nombreError:false,
            emailError: false,
            pwdError: false,
            repeatpwdError: false
        }
    }
    changeHandlerRegister(e) {
        const register = this.state.registerObj;
        register[e.target.name] = e.target.value;
        this.setState({ registerObj: register});
    }
    changeHandlerRegisterCheckbox(e) {
        const register = this.state.registerObj;
        if(e.target.checked)
            register["terms"] = e.target.value;
        else
            register["terms"] = null;
        this.setState({ registerObj: register});
    }
    signup(e) {
        e.preventDefault();
        this.setState({
            errorRegister : null,
            usernameError:false,
            nombreError:false,
            emailError: false,
            pwdError: false,
            repeatpwdError: false
        });
        const register = this.state.registerObj;
        let send = true;
        let inputs = document.getElementsByClassName("has-error");
        for(let k = 0; k < inputs.length; k++){
            inputs[k].classList.remove("has-error");
        }
        if(!register.username_register || register.username_register.length <4){
            document.getElementById("username_register").classList.add("has-error");
            send = false;
            this.setState({usernameError: "Min 4 characters or is already taken"});
        }
        if(!register.name_register || register.name_register.length <4){
            document.getElementById("name_register").classList.add("has-error");
            send = false;
            this.setState({nombreError: "Min 4 characters or is already taken"});
        }
        if(!register.email_register || !register.email_register.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/i)){
            document.getElementById("email_register").classList.add("has-error");
            send = false;
            this.setState({emailError: "Invalid email"});
        }
        if(!register.password_register || !register.password_register.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#^().+=_~/'"-]{6,}$/g)){
            document.getElementById("password_register").classList.add("has-error");
            send = false;
            this.setState({pwdError: "Min 6 characters, at least one uppercase letter, one lowercase and one number."});
        }
        if(!register.repassword_register){
            document.getElementById("repassword_register").classList.add("has-error");
            send = false;
        }
        if(register.repassword_register !== register.password_register){
            document.getElementById("repassword_register").classList.add("has-error");
            send = false;
            this.setState({repeatpwdError: "Not the same as password"});
        }
        if(!register.terms){
            document.getElementById("terms_register").classList.add("has-error");
            send = false;
        }
        if(send){
            axios.post('/api/user/register', register).then(res => {
                const validRegistration = res.data.valid;
                if (validRegistration) {
                    window.location.reload();
                }
                else if(res.data.error){
                    this.setState({errorRegister : "The "+res.data.error+" already exists."});
                }
                else{
                    this.setState({errorRegister : "Something went wrong."});
                }
            })
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <Row>
                <button className="modalCloseLogin modal-close waves-effect btn-flat close-modal">
                    <span>x</span>
                </button>
                <h3>Register</h3>
                <TextInput
                    name="username_register"
                    id="username_register"
                    s={12}
                    label='USERNAME'
                    onChange={this.changeHandlerRegister}
                    validate minLength={4}
                    maxLength = {50}
                    type='text'
                >
                </TextInput>
                {this.state.usernameError ?
                    <div className="error-class">{this.state.usernameError}</div>
                : null}
                <TextInput
                    name="name_register"
                    id="name_register"
                    s={12}
                    label='FULL NAME'
                    onChange={this.changeHandlerRegister}
                    validate
                    maxLength = {50}
                    type='text'
                >
                </TextInput>
                {this.state.nombreError ?
                    <div className="error-class">{this.state.nombreError}</div>
                : null}
                <TextInput
                    name="email_register"
                    id="email_register"
                    s={12}
                    label='EMAIL ADDRESS'
                    onChange={this.changeHandlerRegister}
                    validate
                    maxLength = {120}
                    type='email'
                >
                </TextInput>
                {this.state.emailError ?
                    <div className="error-class">{this.state.emailError}</div>
                : null}
                <TextInput
                    name="password_register"
                    id="password_register"
                    s={12}
                    label='PASSWORD'
                    onChange={this.changeHandlerRegister}
                    validate
                    maxLength = {30}
                    type='password'
                >
                </TextInput>
                {this.state.pwdError ?
                    <div className="error-class">{this.state.pwdError}</div>
                : null}
                <TextInput
                    name="repassword_register"
                    id="repassword_register"
                    s={12}
                    label='RETYPE PASSWORD'
                    onChange={this.changeHandlerRegister}
                    validate
                    maxLength = {30}
                    type='password'
                >
                </TextInput>
                {this.state.repeatpwdError ?
                    <div className="error-class">{this.state.repeatpwdError}</div>
                : null}
                <Col s={12} className="checkbox-terms">
                    <Checkbox label="I have read & accept the " onChange={this.changeHandlerRegisterCheckbox} value="1" id="terms_register"/>
                    <a href="/About/Terms" className="link"> &nbsp;terms & conditions</a>.
                </Col>
                <div className="actions-modal">
                    <Button
                        onClick={this.signup}
                        waves='light'
                    >
                        Sign up
                    </Button>
                    {this.state.errorRegister ?
                        <small className="error-message">{this.state.errorRegister}</small>
                        : null
                    }
                    <small onClick={this.props.showLogin}>
                        <a href='#SingUp'>Already have an account?</a>
                        <p><a href='#SignIn'>SIGN IN</a></p>
                    </small>
                </div>
            </Row>
        );
    }
}

export default Register;