import React, { Component } from 'react';

/**
 * Use redirect for validate and filter users
 */
import {Redirect} from 'react-router-dom';

/**
 * Navigation menu for the admin
 */
import Navigation from '../../components/Admin/Navigation';

/**
 * Same login for all the app
 */
import Login from '../../components/login/Forms/Login';

/**
 * Preloading view for navigation transition
 */
import AdminLoading from '../../components/Admin/Loading';

/**
 * Dashboard components for Admin
 */
import Dashboard from '../../components/Admin/Dashboard';

/**
 * Subscription components for Admin
 */
import Subscription from '../../components/Admin/Subscription';

/**
 * Model components for Admin
 */
import Model from '../../components/Admin/Model';

/**
 * Post components for Admin
 */
import Post from '../../components/Admin/Post';

/**
 * User components for Admin
 */
import User from '../../components/Admin/User';

import './style.css';

import {
    Container, Row, Col
} from 'react-materialize';

import axios from "axios";

/**
 * Manage all Admin views
 * @method validateSesion async
 * @method getDataIndex async
 * @method showContent render
 * @method showIndex render
 * @method showLogin render
 * Feel free to refact
 */
class Admin extends Component {

    constructor(props){

        super(props);

        /**
         * Initial state for validate
         * @param {bool} is_logged user has a active session
         * @param {bool} is_authorized user is_logged and is admin 1
         */
        this.state = {
            is_logged: false,
            is_authorized: false,
            loading: true
        };

    }

    /**
     * Same functionality in App :(
     * To refact
     */
    validateSesion = async() => {

        return await axios.get('/api/user/authenticated')
        .then(res => {
            this.setState({
                is_logged: ( res.data ),
                is_authorized: (res.data && res.data.user_type_id === 1),
                loading: false
            });
        })
        .catch(err => {
            console.log(err);
            console.error('Server response with: ' + err);
            this.setState({loading: false});
        });

    }

    /**
     * Return the render container for the admin login
     * Use @source { '/components/login/Forms/Login' }
     */
    showLogin() {
        return (<>
            <Container>
                <Row>
                    <Col id="admin-login-container" className="col m12 l6 offset-l3">
                        <Login showLinks={false} />
                    </Col>
                </Row>
            </Container>
        </>);
    }

    showIndex() {

        return (
            <>
                <Navigation />
                <Dashboard />
            </>
        );
    }

    showSubscription() {

        return (
            <>
                <Navigation />
                <Subscription />
            </>
        );
    }

    showModel() {

        return (
            <>
                <Navigation />
                <Model />
            </>
        );
    }

    showPost() {

        return (
            <>
                <Navigation />
                <Post />
            </>
        );
    }

    showUser() {

        return (
            <>
                <Navigation />
                <User />
            </>
        );
    }

    showContent(){

        if(!this.state.is_logged)
            return this.showLogin();

        if(!this.state.is_authorized)
            return (<Redirect to="/" />);

        let { pathname } = this.props.location;

        if (pathname.includes('admin/models'))
            return this.showModel();

        if (pathname.includes('admin/subscriptions'))
            return this.showSubscription();

        if (pathname.includes('admin/posts'))
            return this.showPost();

        if(pathname.includes('admin/users'))
            return this.showUser();


        return this.showIndex();

    }

    /**
     * Validate whats be mount
     */
    componentDidMount(){

        this.validateSesion();

    }

    render() {
        return (this.state.loading)
            ? <>
                <Navigation />
                <Container className="admin-container">
                    <AdminLoading />
                </Container>
            </>
            : this.showContent();
    }

}

export default Admin;