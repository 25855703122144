import React, {Component} from 'react';
import axios from "axios";
import {Icon, Button} from 'react-materialize';
import Login from "../../login";
import './style.css';

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state={
            video : null,
            images : []
        };
    }
    componentDidMount() {
        this.getGallery();
    }
    getGallery = async () => {
        axios.post("/api/user/gallery",{user : this.props.user}).then( resp => {
            this.setState({
                video : resp.data.video,
                images : resp.data.images
            })
        })
    }
    renderGallery = () => {
        const images = [...this.state.images];
        return images.map((item,index) => {
            return (
                <div key={index}
                    className={ this.props.authenticated ? 'grid-post' : 'grid-post modal-trigger'}
                    href={ this.props.authenticated ? '#' : '#loginModal'}
                    onClick={ !this.props.authenticated ? null : (e) => this.submitSubscribe(e)}
                    style={{backgroundImage: "url(" + item.uri + ")"}}
                >
                <Icon>add</Icon>
                </div>
            )
        });
    }
    submitSubscribe = async (e) => {
        document.getElementById("submitSuscription").click();
    }
    render (){
        return (
            <div className="gallery">
                {this.state.video ?
                    <div className="video">
                        <video
                            preload="yes"
                            poster={this.state.video.poster}
                            className="responsive-video"
                            controls
                            controlsList="nodownload"
                        >
                            <source src={this.state.video.uri} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    : null
                }
                { this.state.images.length > 0 ?
                    <div>
                        <h3>Gallery</h3>
                        <hr />
                        {this.renderGallery()}
                        { !this.props.authenticated ?
                            <div>
                                <Login showModalBig={true} />
                                <Button
                                    className='modal-trigger btn-gradient-streamx btn-featured btn-suscribe'
                                    href="#loginModal">
                                    Subscribe Now
                                </Button>
                            </div>
                            :
                            <form id="subscribe-form" action={this.props.modelCost.ccbillFlexForm}>
                                <input
                                    type="hidden"
                                    name="artist_id"
                                    value={this.props.modelCost.model_id} />
                                <input
                                    type="hidden"
                                    name="user_id"
                                    value={this.props.modelCost.user_id} />
                                <Button
                                    type="submit"
                                    id="submitSuscription"
                                    className='subscribe-second-btn btn-gradient-streamx btn-featured btn-suscribe'>
                                    Subscribe Now
                                </Button>
                            </form>

                        }
                    </div>
                    : null
                }
            </div>
        )
    };
}
export default Gallery;