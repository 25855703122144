import React, { Component } from 'react';
import {Button, Row, TextInput} from "react-materialize";
import axios from "axios";

class Login extends Component {
    constructor(){
        super();
        this.state = {
            username : '',
            password : '',
            models : [],
            devices : false,
            nombreError: false,
            pwdError: false,
            errorLogin : null
        }
        this.keyPress = this.keyPress.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.login = this.login.bind(this);
        this.cancelDevices = this.cancelDevices.bind(this);
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.login()
        }
    }
    componentDidMount() {
        this.getModels();
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    getModels = () => {
        axios.get('/api/home/getModels').then(res => {
            this.setState({models : res.data});
        })
    }
    login = () => {
        this.setState({errorLogin : null, nombreError: false, pwdError: false});
        let send = true;
        let inputs = document.getElementsByClassName("has-error");
        for(let k = 0; k < inputs.length; k++){
            inputs[k].classList.remove("has-error");
        }
        if(this.state.username.length <1){
            document.getElementById("username_login").classList.add("has-error");
            send = false;
            this.setState({nombreError: "Empty field"});
        }
        if(this.state.password.length <1){
            document.getElementById("password_login").classList.add("has-error");
            send = false;
            this.setState({pwdError: "Empty field"});

        }

        const user = {
            user: this.state.username.trim(),
            pw: this.state.password,
        }
        if(this.state.devices){
            user["devices"] = true;
        }
        if(send){
            axios.post('/api/user/login', user).then(res => {
                const validRegistration = res.data.valid;
                let modelos = this.state.models;
                const name = window.location.pathname;
                var splitPath = name.split("/");
                if (validRegistration) {
                    if(res.data.type === 2){
                        window.location = "/" + res.data.username;
                    }
                    else{
                        let pathModel = 0;
                        modelos.forEach(item => {
                            if (splitPath[1] === item["user.username"]) {
                                pathModel = 2;
                            }
                        });
                        if (pathModel === 2){
                            window.location.reload(); 
                        } else {
                            window.location = "/" + res.data.username + "/Feed"
                        }    
                   }
                }
                else if(res.data.devices){
                    this.setState({devices: true});
                }
                else{
                    this.setState({errorLogin : "Incorrect username or password"});
                }
            })
        }
    }
    cancelDevices = () => {
        this.setState({devices : false});
    }
    render() {
        return (
            <Row>
                <button className="modalCloseLogin modal-close waves-effect btn-flat close-modal">
                    <span>x</span>
                </button>
                <h3>Login</h3>
                {this.state.devices ?
                    <div>
                        <p className="message-info">You have already loged in ohter to devices, if you continue, your other sessions will
                            be disabled</p>
                        <div className="actions-modal">
                            <Button
                                onClick={(e) => this.login(e)}
                                waves='light'
                            >
                                Sign In
                            </Button>
                            <small onClick={this.cancelDevices}>
                                <a href='#SingUp'>Cancel</a>
                            </small>
                        </div>
                    </div>
                    :
                    <div>

                        <TextInput
                            onKeyPress={this.keyPress}
                            onChange={this.changeHandler}
                            id="username_login"
                            name="username"
                            value={this.state.username}
                            s={12}
                            label='USERNAME / EMAIL'
                            validate minLength={4}
                            maxLength = {50}
                        >
                        </TextInput>
                        {this.state.nombreError ? 
                            <div className="error-class">{this.state.nombreError}</div>
                        : null}
                        <TextInput
                            onKeyPress={this.keyPress}
                            onChange={this.changeHandler}
                            id="password_login"
                            name="password"
                            value={this.state.password}
                            s={12}
                            label='PASSWORD'
                            validate minLength={6}
                            maxLength = {30}
                            type='password'
                        >
                        </TextInput>
                        {this.state.pwdError ? 
                            <div className="error-class">{this.state.pwdError}</div>
                        : null}
                        <div className="actions-modal">
                            <Button
                                onClick={this.login}
                                waves='light'
                            >
                                Sign in
                            </Button>
                            {this.state.errorLogin ?
                                <small className="error-login">{this.state.errorLogin}</small>
                                : null
                            }
                            {this.props.showLinks
                                ? <>
                                    <small>
                                        <a href='#Resetpassword' onClick={this.props.showForgotPassword}>Forgot Password?</a>
                                    </small>
                                    <small onClick={this.props.showSignup}>
                                        <a href='#SingUp'>Don't have an account?</a>
                                        <p><a href='#SingUp'>SIGN UP</a></p>
                                    </small>
                                </>
                                : <></>
                            }
                        </div>
                    </div>
                }
           </Row>
        );
    }
}

export default Login;