import React, { Component } from 'react';
import {Modal, Row, Button, Icon} from 'react-materialize';
import Cropper from 'react-easy-crop';
import './style.css';
class Crop extends Component {
    
    render(){
        return (
            <Modal open={this.props.openCrop} options={{dismissible : false}} className="modal-cropper">
                <Row>
                    <button href="#!" className="modal-close waves-effect btn-flat close-modal" onClick={this.props.closeCrop}>
                        <span>x</span>
                    </button>
                    <div className="cropper">
                        <Cropper
                            image={this.props.imageSrc}
                            crop={this.props.crop}
                            zoom={this.props.zoom}
                            aspect={this.props.aspect}
                            cropShape={this.props.shapeCrop}
                            onCropChange={this.props.onCropChange}
                            onCropComplete={this.props.onCropComplete}
                            onZoomChange={this.props.onZoomChange}
                        />
                    </div>
                    <div className="slideFix">
                        <Icon className="minus">remove</Icon>
                        <Icon className="plus">add</Icon>
                        <input type="range" min="1" max="3" step="0.1" name='zoomScroll' value={this.props.zoom} className="slider" id="myRange" onChange={(e, zoom) => this.props.onZoomChangeBar(e,zoom)}/>
                    </div>
                    <div className="actions-modal">
                        <Button
                            waves='light'
                            onClick={this.props.showCroppedImage}
                        >
                            Save
                        </Button>
                    </div>
                </Row>
            </Modal>
        )
    }
}
export default Crop;