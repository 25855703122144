import React, { Component } from 'react';
import { Icon, Modal, Button } from 'react-materialize';

class ModalConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalId: '',
            message: '',
            data: '',
        };
    }

    setData() {
        let modelId = this.props.id || 'Modal-' + (Math.round(Math.random() * 100000));
        let message = this.props.message || 'You confirm the requested action?'
        let data = this.props.data || {};
        this.setState({
            modalId: modelId,
            message: message,
            data: data
        })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.data !== this.props.data)
            this.setData();

    }

    componentDidMount() {
        this.setData();
    }

    render() {

        return (
            <Modal className="modal-confirm" id={this.state.modalId}>
                <span className="close modal-close">
                    <Icon>cancel</Icon>
                </span>
                <br />
                <p>{this.state.message}</p>
                <div id="cancelSubscriptionContainer" className="actions">
                    <ul>
                        <li>
                            <Button className="go" modal="close" waves="light">
                                No
                            </Button>
                        </li>
                        <li>
                            <Button
                                data={this.state.data}
                                className="cancel"
                                onClick={this.props.onConfirm}>Yes
                            </Button>
                        </li>
                    </ul>
                </div>
            </Modal>
        )
    };
}
export default ModalConfirm;