import React, { Component } from 'react';

import Table from '../../Table';

import {
    Container, Col, Row
} from 'react-materialize';
import axios from 'axios';
import Loading from '../Loading';


class Model extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [{}],
            source: { uri: "/api/admin/get-models" }
        };
    }

    buildData(data) {

        data.map(
            (item, key) => {
                item.flexform = <a target="_blank" href={item.flexform}>
                    CCBILL Form
                </a>;
                item.Action = ' ';
            }
        );
        return data;
    }

    async getData() {
        axios.get(this.state.source.uri)
        .then(res => {
            if(res.status === 200){
                let dataToTable = this.buildData(res.data);
                this.setState({
                    data: dataToTable,
                    loading: false
                });
            }

        })
        .catch(err => {
            console.log(err);
            this.setState({
                loading: false
            });
        })
    }

    /**
     * Validate whats be mount
     */
    componentDidMount() {
        this.getData();
    }

    render() {

        const content = this.state.loading
            ? <Loading />
            : <Table id="table-models" data={this.state.data} />

        return <Container className="admin-container">
            <Row>
                <Col s={12} className="admin-box">
                    <h5>Models</h5>
                    {content}
                </Col>
            </Row>
        </Container>;
    }

}

export default Model;