import React, { Component } from 'react';


class Tbody extends Component {

    constructor(props) {

        super(props);

        this.state = {
            hasContent: false,
            paginate: {
                hasPaginate: false,
                numberPages: 0,
                resultPerPage: 0,
            },
            totalResult: 0,
            result: <></>
        }

    }

    /**
     * return array of tds content for a single tr
     * @param {object} object {key: value, key1: value1}
     */
    parseTd(obj){

        let length = Object.keys(obj).length;
        let result = <td>Whitout result</td>;

        if (length > 0) {

            result = [];
            let key = '';
            for (key of Object.keys(obj)) {
                let col = '/';
                if (typeof obj[key] === 'string'
                    || React.isValidElement(obj[key])
                    || (typeof obj[key] === 'number' && isFinite(obj[key]))
                )
                    col = obj[key];

                /*if(Array.isArray(obj[key]))
                    Object.values(obj[key]).map(elem => { return elem }).join(' / ');*/


                result.push(<td key={key + 1}>{col}</td>);

            }

        }

        return result;
    }

    build(){

        //Data be an objects array [{}, {}, ...]
        if (!Array.isArray(this.props.data))
            throw new Error('prop data for Tbody need be an array');

        let data = this.props.data;
        let rows = this.props.data.length;
        let rowsPerPage = Number.isInteger(this.props.rowsPerPage)
            ? (this.props.rowsPerPage > 0 ? this.props.rowsPerPage : 10)
            : 10;
        let pages = Math.ceil(rows / rowsPerPage);
        let result = <tr><td colSpan={rows}>Sin resultados :/</td></tr>;
        let hasContent = rows > 0;
        let paginate = {
            active: (pages > 1),
            pages: pages,
            rowsPerPage: rowsPerPage
        };

        if (hasContent) {

            result = [];

            let count = 1;
            let pageCount = 1;
            data.forEach((row, key) => {

                let hiddenRow = (count > rowsPerPage);
                let tds = this.parseTd(row);

                //Attribute hidden work if its present on the element
                result.push((hiddenRow
                    ? <tr data-page={pageCount} hidden={hiddenRow} key={key}>
                        {tds}
                    </tr>
                    : <tr data-page={pageCount} key={key}>
                        {tds}
                    </tr>
                )
                );
                if (count % rowsPerPage === 0)
                    pageCount++;
                count++;

            });


        }
        //Elevate state
        this.props.paginate(paginate);

        this.setState({
            result: result,
            hasContent: hasContent,
            paginate: paginate
        });

    }

    componentDidMount() {

        this.build();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.data !== this.props.data
            || prevProps.rowsPerPage !== this.props.rowsPerPage)
            this.build();

    }

    render() {

        return (this.state.hasContent)
            ? <tbody>{this.state.result}</tbody>
            : <tbody><tr>
                <td>Whitout result</td>
            </tr></tbody>;

    }

}

export default Tbody;