import React, {Component} from 'react';
import {
    Link
} from 'react-router-dom';
import {
	Dropdown
} from 'react-materialize';
import Login from "../../login";
import './style.css';
import axios from "axios";
import ProfileSettings from "../../Profile/ProfileSettings";
class Navbar extends Component {
	constructor(props){
		super();
	}

	logout() {
        axios.get('/api/user/logout').then(res => {
            window.location = "/";
        });
    }
    reUpload = (e) => {
    	setTimeout(function(){ window.location.reload(); }, 100);
    }
	render (){
		return (
			<div className="nav-main">
				<a className="image-nav" href="/"><img className='img-responsive' src='https://storage.googleapis.com/streamx-251917.appspot.com/public/img/logo-new.png' alt='logo' /></a>
				{!this.props.user ?
					<Login
						token={this.props.token ? this.props.token : null}
						unsetToken={this.props.unsetToken ? this.props.unsetToken : () => {return false}}
						showModalBig={true}
						trigger={<button className="z-depth-4 login-btn" waves='light'>Login</button>} />
					:
					<Dropdown trigger={
						<button className="z-depth-4 login-btn user-button" waves='light'>
							<span className="image" style={{backgroundImage : "url(" + this.props.user["uri"] + ")" }}></span>
							<span className="text">{this.props.user.username}</span>
						</button>
					}
					options={{
						alignment : "left",
						closeOnClick: false,
					}}>
						<Link to={"/" + this.props.user["username"] + "/Feed/Messages"}>
							Messages
						</Link>
						<ProfileSettings trigger={
								<Link to="#ProfileSettings">
									Profile Settings
								</Link>
							}
						/>
						<Link to={"/" + this.props.user["username"]} onClick={(e) => this.reUpload()}>
							My Profile
						</Link>
						<Link to={"/" + this.props.user["username"] + "/Feed"}>
							My Account
						</Link>
						<li>
							<a href="#LogOut" onClick={this.logout}>
								Logout
							</a>
						</li>
					</Dropdown>
				}
			</div>
		)
    };
}
export default Navbar;