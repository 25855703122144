import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button, Divider } from 'react-materialize';
import './style.css';
class Footer extends Component {
    constructor(){
        super();
        this.state = {
        };
    }
    componentDidMount(){
        
    }


    render (){
        return (
            <div className="footer">
                <div className="footer-content">
                    <div>
                        <span>
                            <Link to={"/About/Privacy"}>PRIVACY POLICY</Link>
                        </span>
                        <span className="separator">|</span>
                        <span>
                            <Link to='/About/Terms'>TERMS & CONDITIONS</Link>
                        </span>
                        <span className="separator">|</span>
                        <span>
                            <Link to='/About/FAQ'>FAQ</Link>
                        </span>
                        <span className="separator">|</span>
                        <span>
                            <Link to='/About/UpdatePolicy'>UPDATE POLICY & INFORMATION</Link>
                        </span>
                    </div>
                    <Dropdown className='dropSupport' 
                        options={{
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            container: null,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250
                        }}
                        trigger={
                            <Button node="button" className="support">
                                <i className="material-icons">
                                    phone_in_talk
                                </i>
                                Support                      
                            </Button>
                        }>
                        <Link id='ul-a' to={"/About/Support"}>
                            <i className="material-icons">
                                email
                            </i>
                            Contact Support
                        </Link>
                        <Divider />
                        <a href="http://support.ccbill.com">
                            <i className="material-icons">
                                unsubscribe
                            </i>
                            Cancel Subscription
                        </a>
                    </Dropdown>                    
                    </div>
            </div>
        )
    };
}
export default Footer;