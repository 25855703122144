import React, { Component } from 'react';
import {
    Select, TextInput, Button, Icon, Row, Col
} from 'react-materialize';
import axios from "axios";
import Loading from '../../Loading';


class AddSubscriptionForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            subscriptionId: "",
            email: "",
            model: "",
            result: "",
            uriValidate: "/api/admin/subscription-info",
            uriAdd: "/api/admin/subscription-add",
            uriGetModels: "/api/admin/get-model-select",
            modelOptions: <option>Without data</option>,
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.getInfoSubscription = this.getInfoSubscription.bind(this);

    }

    onChangeHandler(e) {

        let target = e.target;
        let value = target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });

    }

    async fillModelSelect() {
        return await axios.get(this.state.uriGetModels)
            .then(res => {
                let options  = [];
                options.push(<option key="null">Choose one</option>);
                if (res.status === 200 && res.data !== ""){
                    let data = res.data;

                    data.forEach(element => {
                        options.push(
                            <option key={element.id} value={element.id}>
                                {element.value}
                            </option>
                        );
                    });
                }
                this.setState({ modelOptions: options});

            })
            .catch(err => {
                console.log(err);
                return false;
            });
    }

    async onSubmitHandler(e) {
        e.preventDefault();
        if (this.state.subscriptionId.length > 10
            && this.state.email.length > 6
            && this.state.model !== "") {
            this.setState({ loading: true });

            let params = {
                subscriptionId: this.state.subscriptionId,
                email: this.state.email,
                model: this.state.model
            };
            return await axios.post(this.state.uriAdd, params)
                .then(res => {
                    if (res.status === 200 && res.data !== "")
                        this.props.onSearch(JSON.stringify(res.data));

                    this.setState({ loading: false });

                })
                .catch(err => {
                    this.props.onSearch('Error in the request');
                    console.log(err);
                    this.setState({ loading: false });
                });
        }
    }

    async getInfoSubscription() {
        if (this.state.subscriptionId.length > 10){
            this.setState({ loading: true });
            return await axios.post(this.state.uriValidate, {
                subscriptionId: this.state.subscriptionId,
                model: this.state.model
            })
                .then(res => {
                    if (res.status === 200 && res.data !== "")
                        this.props.onSearch(JSON.stringify(res.data));

                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.props.onSearch('Error in the request');
                    console.log(err);
                    this.setState({ loading: false });
                });
        }
    }

    componentDidMount() {
        this.fillModelSelect();
    }

    render() {

        const actions = (this.state.loading)
            ? <Loading />
            : (<>
                <Button className="button-admin" small onClick={this.getInfoSubscription} type="button" waves="light">
                    Validate CCBILL
                    <Icon right>
                        check
                    </Icon>
                </Button>
                <Button className="button-admin" small type="submit" waves="light">
                    Asign
                    <Icon right>
                        add
                    </Icon>
                </Button>
            </>);

        return <Row>
                    <Col>
                <form onSubmit={this.onSubmitHandler} >
                    <TextInput
                        name="subscriptionId"
                        onChange={this.onChangeHandler}
                        value={this.state.subscriptionId}
                        label="CCBILL ID"
                    />
                    <TextInput
                        name="email"
                        onChange={this.onChangeHandler}
                        value={this.state.email}
                        label="User email"
                    />
                    <Select
                        label='Model'
                        name="model"
                        onChange={this.onChangeHandler}
                        value={this.state.model}>
                        {this.state.modelOptions}
                    </Select>
                    {actions}
                </form>
            </Col>
        </Row>;
    }

}

export default AddSubscriptionForm;