import React, {Component} from 'react';
import {Icon, Modal,Button} from 'react-materialize';
import './style.css';

class ModalOpenSource extends Component {

    componentDidMount() {
    }
    
    render (){
        return (
            <Modal className="modal-confirm" open={true} options={{dismissible : false}}>
                <span className="close" onClick={(e) => this.props.cancelDelete(e)}><Icon>cancel</Icon></span>
                <p>Are you sure to delete?</p>
                <div className="actions">
                    <ul>
                        <li><Button className="go" onClick={(e) => this.props.deletePost(e)}>Delete</Button></li>
                        <li><Button className="cancel" onClick={(e) => this.props.cancelDelete(e)}>Cancel</Button></li>
                    </ul>
                </div>
            </Modal>
        )
    };
}
export default ModalOpenSource;