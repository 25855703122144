import React, { Component } from 'react';

class Pagination extends Component {

    constructor(props) {

        super(props);
        this.state = {
            active: 1
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){

        e.preventDefault();
        let active = e.target.getAttribute('data-paginator') * 1 || 1;
        this.refresh(active);

    }

    refresh(active){

        let table = document.querySelector("#" + this.props.tableId);
        let rows = table.querySelectorAll('[data-page]')

        rows.forEach(item => {

            item.setAttribute('hidden', ' ');
            let rowPage = item.getAttribute('data-page') * 1;
            if (rowPage === active)
                item.removeAttribute('hidden');

        });

        this.setState({ active: active });

    }

    componentDidUpdate(prevProps) {

        if (prevProps.paginate !== this.props.paginate)
            this.refresh(1);

    }

    render() {

        let pages = [];
        let numberPages = this.props.paginate.numberPages || 1;
        if(numberPages > 1)
            for (let i = 1; i <= numberPages; i++) {
                pages.push(
                    <button
                        key={'paginator-' + i}
                        data-paginator={i}
                        onClick={this.handleClick}
                        className={(i === (this.state.active * 1))
                            ? "waves-effect waves-light btn-small active"
                            : "waves-effect waves-light btn-small"
                        }>
                        {i}
                    </button>
                );
            }

        return <div className="pagination-container">
            <span className="left">
                Showing {this.props.paginate.resultPerPage} rows per page. Page {this.state.active} of {numberPages} <br />
            </span>
            {pages}
        </div>;

    }

}

export default Pagination;