import React, { Component } from 'react';

import Loading from '../Admin/Loading';

import Thead from './Thead';
import Tbody from './Tbody';
import Pagination from './Pagination';

import {Table as TableM} from "react-materialize";

import axios from "axios";

import './style.css'

/**
 * Create an Materialize dataTable Component
 * @property array data [{}, {}, ...]
 * @property object options {uri, uriParams, responsive, centered, hoverable}
 * @property string id to set the pagination on multiple instances
 * @property array thead [a, b, ...] Set modified datatable thead
 * Has a bug on update param options.uriParams not re-render
 */
class Table extends Component {

    constructor(props) {

        super(props);

        this.state = {
            table: '',
            options: {},
            total: 0,
            thead: {},
            tbody: {},
            paginate: '',
            loading: true
        };

        this.setPaginate = this.setPaginate.bind(this);

    }

    /**
     * Get data for build the table
     * props data has preference over options.uri
     * @return array [{}]
     */
    async getData(){

        let useDataProp = Array.isArray(this.props.data);
        let useDataUri = (this.props.options !== undefined
            && typeof this.props.options.uri === 'string');
        let existSource = (useDataProp || useDataUri);

        try {

            if (!existSource)
                throw new Error('Table need a valid data source');

            if (useDataProp)
                return this.props.data;

            let uriParams = this.props.options.uriParams || {};
            return await axios.get(this.props.options.uri, {params: uriParams})
                .then(res => {
                    return (res.status === 200)
                        ? res.data
                        : [{}];
                }).catch(err => {
                    console.error(err);
                    return [{}];
                });

        }
        catch (e) {
            console.log(e);
        }

    }

    /**
     * Validate and get the source data to work
     * Use props.data || props.options.uri [{}, {}, ...]
     * run setState
     */
    async build(){

        //console.log('build Table');

        let tableId = this.props.id || 'Table-' + (Math.round(Math.random() * 100000));
        let data = await this.getData();
        let thead = Array.isArray(this.props.thead)
            ? this.props.thead
            : (Object.keys((data[0] || {})));
        let tbody = data;
        let total = data.length;

        this.setState({
            table: tableId,
            loading: false,
            thead: thead,
            tbody: tbody,
            total: total,
            paginate: {
                active: false,
                pages: 0,
                resultsPerPage: 10
            }
        });

    }

    /**
     * Elevate the state for create a pagination from Tbody
     * @param paginate
     */
    setPaginate(paginate){
        this.setState({paginate: paginate});
    }

    componentDidMount() {
        this.build();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.options !== this.props.options
            || prevProps.data !== this.props.data)
            this.build();

    }

    render() {

        const tableId = this.state.table;
        const responsive = this.props.responsive || true;
        const centered = this.props.centered || true;
        const hoverable = this.props.hoverable || true;

        return (this.state.loading)
            ? <Loading />
            : (<>
                <div>Total results: {this.state.total}</div>
                <div className="table-container">
                    <TableM
                        id = {tableId}
                        responsive={responsive}
                        centered={centered}
                        hoverable={hoverable}>

                        <Thead data={this.state.thead} />
                        <Tbody paginate={this.setPaginate} data={this.state.tbody} />
                    </TableM>
                </div>
                <Pagination tableId={tableId} paginate={this.state.paginate} />
                </>);
    }

}

export default Table;