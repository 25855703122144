import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';
import './style.css';

class Loading extends Component {

    constructor(props) {
        super();
    }

    render() {

        return (
            <Row>
                <Col className="l6 offset-l3 center-align m-t-5p">
                    <div className="section">
                        <div className="preloader-wrapper big active">
                            <div className="spinner-layer spinner-red">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3>Loading...</h3>
                </Col>
            </Row>
        );

    }

}

export default Loading;