import React, { Component } from 'react';
import {Button, Col, Row} from 'react-materialize';
import Navbar from '../../components/Main/Navbar';
import Messages from '../../components/Messages';
import axios from 'axios';
import moment from 'moment';
import './style.css';
import io from "socket.io-client";
const userIcon = 'https://storage.googleapis.com/streamx-251917.appspot.com/public/user.png';
class ModelFeed extends Component {
    constructor(props){
        super(props);
        this.state = {
            user : '',
            tab : (props.displayMessages) ? 1 : 0,
            show: 0,
            subscribers : [],
            conversations : [],
            chat : null,
            token : null,
            limitedSubs: 15,
            lastLimitSubs: 0,
            limitedMsg: 15,
            lastLimitMsg: 0,
            totalSubs: [],
            totalChats: []
        }
        this.closeChat = this.closeChat.bind(this);
    }
    componentDidMount() {
        this.setState({user : this.props.authenticated});
        this.getSubsCount();
        this.getChatsCount();
        this.getSubscribers(this.state.limitedSubs);
        this.getConversations(this.state.limitedMsg);
        this.getToken();
    }
    getToken = async () => {
        await axios.get('/api/user/getMessageKey').then(async (res) => {
            this.setState({token : res.data.token});
            this.socket = io('/',{
                path: '/api/messages/send/',
                query: 'token='+res.data.token
            });
            this.socket.on('RECEIVE_MESSAGE', async (data)=>{
 
                let newMessage = {
                    message : data.message,
                    id : data.id,
                    created_at : data.created_at,
                    client : data.chat_from,
                    username : data.destinatary,
                    destUser : data.chat_from,
                    destName : data.destName,
                    destImage : data.image
                };
                let currId = null
                const conversations = [...this.state.conversations];
                await conversations.forEach((item,index) => {
                    if(data.chat_from ===  item.destUser){
                        currId = index;
                    }
                });
                if(currId !== null){
                    conversations.splice(currId,1);
                }
                conversations.splice(0, 0, newMessage);
                this.setState({conversations : conversations});
            });
        })
    }

    getSubsCount = async () => {
        await axios.get('/api/model/get/subscribers/')
        .then( async (resp) => {
            this.setState({totalSubs : resp.data})
        })
    }
    getChatsCount = async () => {
        await axios.get('/api/model/get/chats/')
        .then( async (resp) => {
            this.setState({totalChats : resp.data})
        })
    }
    getSubscribers = async (limit) => {
        await axios.get('/api/model/get/subscribers/', {params: {limit: limit}})
        .then( async (resp) => {
            this.setState({subscribers : resp.data})
        })
    }
    getConversations = async (limit, unread=false) => {
        await axios.get('/api/model/get/chats/', {
            params: {
                limit: limit,
                unread: unread
            }
        })
        .then( async (resp) => {
            this.setState({conversations : resp.data})
        })
    }
    moreSubscriptions= async(e,limit)=>{
        e.preventDefault();
        const newLimit = limit + 15;
        await this.setState({limitedSubs: newLimit, lastLimitSubs: limit});

        await axios.get('/api/model/get/subscribers/', {params: {limit: newLimit}})
            .then( async (resp) => {
                await this.setState({subscribers : resp.data});
            });
    }
    moreMessages= async(e,limit)=>{
        e.preventDefault();
        const newLimit = limit + 15;
        await this.setState({limitedMsg: newLimit, lastLimitMsg: limit});
        await axios.get('/api/model/get/chats/', {
            params: {
                limit: newLimit,
                unread: this.state.show ? true : false
            }})
            .then( async (resp) => {
                await this.setState({conversations : resp.data});
            });
    }
    changeTab = (e, tab) => {
        e.preventDefault();
        this.setState({ tab : tab});
    }
    openChat = async (e, model) => {
        this.setState({chat : model});
    }
    drawSubscribers = () => {
        const subscribers = [...this.state.subscribers];
        return subscribers.map((item,index) => {
            return (
                <Col key={index} s={6} m={4} className="card-item-notification">
                    <div className="notification-item item-subscriber" key={index} onClick={(e) => this.openChat(e, item.destModel)}>
                        <img alt={item.username} src={item.uri ? item.uri : userIcon}></img>
                        <div>
                            <p>{item.display_name}</p>
                        </div>
                    </div>
                </Col>
            )
        });
    }
    drawMessages = () => {
        const conversations = [...this.state.conversations];
        return conversations.map((item,index) => {
            let time = moment(item.created_at).format('DD MMM, YYYY, hh:mm:ss');
            return (
                <div className="notification-item item-msg" key={index} onClick={(e) => this.openChat(e, item.destUser)}>
                    <img alt={item.destName} src={item.destImage ? item.destImage : userIcon}></img>
                    <div>
                        <h6>
                            {item.destName}
                        </h6>
                        <p>{item.message}</p>
                        <span>{time}</span>
                    </div>
                </div>
            )
        });
    }
    showMsg = (e, tab) => {
        e.preventDefault();
        if (tab === 0)
            this.getConversations(this.state.limitedMsg);
        else
            this.getConversations(this.state.limitedMsg, true);
        this.setState({show : tab});
    }
    closeChat = (e) => {
        this.setState({chat : null});
    }
    render() {
        return (
            <div className='main main-content'>
                <Navbar user={this.state.user}/>
                <div className="main-container">
                    <div className="container-fluid">
                        <h2 className="main-title">MY ACCOUNT</h2>
                        <ul className="tab-menu">
                            <li className={(this.state.tab === 0) ? "active" : null} onClick={ (e) => this.changeTab(e,0)}>
                                <a href="#MyFeed">My Subscribers</a></li>
                            <li className={(this.state.tab === 1) ? "active" : null} onClick={ (e) => this.changeTab(e,1)}>
                                <a href="#Messages">Messages</a></li>
                        </ul>
                        <div className="tab-container">
                            {
                                (this.state.tab === 0) ?
                                    <Row className="notifications">
                                        <p className="tab-title">My Subscribers</p>
                                        {this.drawSubscribers()}
                                        {this.state.subscribers.length !== this.state.totalSubs.length ?
                                            <Col s={12} m={12} >
                                                <Button className='more' onClick={(e)=>this.moreSubscriptions(e,this.state.limitedSubs)}>More</Button>
                                            </Col>
                                        : null}
                                    </Row>
                                :
                                    (this.state.tab === 1) ?

                                        <div className="text-left notifications">
                                            <p className="tab-title">Messages</p>
                                            <ul className='tab-menu' >
                                                <li className={(this.state.show === 0) ? "active" : null} onClick={ (e) => this.showMsg(e,0)}>
                                                    <a href="#all">All</a>
                                                </li>
                                                <li className={(this.state.show === 1) ? "active" : null} onClick={ (e) => this.showMsg(e,1)}>
                                                    <a href="#unread">Unread</a>
                                                </li>
                                            </ul>
                                            { this.state.chat ?
                                                <Messages
                                                    user={this.state.chat}
                                                    closeChat={this.closeChat}
                                                    token={this.state.token}
                                                    image={this.state.user["uri"]}
                                                    auth={this.state.user.user_id}/>
                                                :
                                                this.drawMessages()
                                            }
                                            {!this.state.chat && this.state.conversations.length !== this.state.totalChats.length ?
                                                <Button className='more' onClick={(e)=>this.moreMessages(e,this.state.limitedMsg)}>More</Button>
                                            : null}
                                        </div>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModelFeed;