import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Checkbox, Button} from 'react-materialize';
import Navbar from '../../components/Main/Navbar';
import Messages from '../../components/Messages';
import axios from 'axios';
import moment from 'moment';
import './style.css';
import io from "socket.io-client";
import CancelSubscription from '../../components/CancelSubscription';

class Feed extends Component {
    constructor(props){
        super(props);

        this.state = {
            user : '',
            tab : (props.displayMessages) ? 2 : 0,
            feed : [],
            subs : [],
            conversations : [],
            chat : null,
            token : null,
            limited: 15,
            lastLimit: 0,
            limitedSubs: 10000,
            lastLimitSubs: 0,
            limitedMsg: 15,
            lastLimitMsg: 0,
            sub_id_to_cancel: ''

        };

        this.closeChat = this.closeChat.bind(this);
        this.socket = null;
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.setSubscriptionCancel = this.setSubscriptionCancel.bind(this);
    }

    componentDidMount() {
        this.setState({user : this.props.authenticated})
        this.getFeed(this.state.limited);
        this.getSubscriptions(this.state.limitedSubs);
        this.getConversations(this.state.limitedMsg);
        this.getToken();
    }
    async getToken() {
        await axios.get('/api/user/getMessageKey').then(async (res) => {
            this.setState({token : res.data.token});
            this.socket = io('/',{
                path: '/api/messages/send/',
                query: 'token='+res.data.token
            });
            this.socket.on('RECEIVE_MESSAGE', async (data)=>{
                let newMessage = {
                    message : data.message,
                    id : data.id,
                    created_at : data.created_at,
                    client : data.chat_from,
                    username : data.destinatary,
                    destUser : data.chat_from,
                    destName : data.destName,
                    destImage : data.image,
                };
                let currId = null
                const conversations = [...this.state.conversations];
                conversations.map((item, index) => {
                    if (data.chat_from === item.destUser) {
                        currId = index;
                    }
                    return item;
                });
                if(currId !== null){
                    conversations.splice(currId,1);
                }
                conversations.splice(0, 0, newMessage);
                this.setState({conversations : conversations});

            });
        })
    }

    changeTab(e, tab){
        e.preventDefault();
        this.setState({tab : tab});
    }


    async cancelSubscription(e){
        e.preventDefault();
        await axios.post('/api/transaction/cancel/', {
            sub_id: this.state.sub_id_to_cancel
        })
            .then(async (resp) => {
                this.setState({ feed: resp.data })
            });
        return true;
    }

    setSubscriptionCancel(e){
        let target = e.target;
        let sub_id = target.getAttribute('data-sub-id');
        this.setState({
            sub_id_to_cancel: sub_id
        })
    }



    async getFeed(limit){
        await axios.get('/api/user/get/feed/', {params: {limit: limit}})
            .then( async (resp) => {
                this.setState({feed : resp.data})
            });
    }
    async getSubscriptions(limit){
        await axios.get('/api/user/get/subscriptions/', {params: {limit: limit}})
            .then( async (resp) => {
                this.setState({subs : resp.data});
            });
    }

    async getConversations(limit){
        await axios.get('/api/user/get/conversations/', {params: {limit: limit}})
            .then( async (resp) => {
                this.setState({conversations : resp.data});
            });
    }
    morePosts= async(e,limit)=>{
        e.preventDefault();
        const newLimit = limit + 15;
        await this.setState({limited: newLimit, lastLimit: limit});

        await axios.get('/api/user/get/feed/', {params: {limit: newLimit}})
            .then( async (resp) => {
                await this.setState({feed : resp.data});
            });
    }
    moreSubscriptions= async(e,limit)=>{
        e.preventDefault();
        const newLimit = limit + 15;
        await this.setState({limitedSubs: newLimit, lastLimitSubs: limit});

        await axios.get('/api/user/get/subscriptions/', {params: {limit: newLimit}})
            .then( async (resp) => {
                await this.setState({subs : resp.data});
            });
    }
    moreMessages= async(e,limit)=>{
        e.preventDefault();
        const newLimit = limit + 15;
        await this.setState({limitedMsg: newLimit, lastLimitMsg: limit});

        await axios.get('/api/user/get/conversations/', {params: {limit: newLimit}})
            .then( async (resp) => {
                await this.setState({conversations : resp.data});
            });
    }
    openChat(e, model){
        this.setState({chat : model});
    }

    drawFeed(){
        const feed = [...this.state.feed];
        return feed.map( (item, index) => {
            let time = moment(item.created_at).format('DD MMM, YYYY');
            return (
                <div className="notification-item item-feed" key={index}>
                    <h6>
                        <Link id='modelname' to={"/" + item['user.username']}>
                            {item['user.model.plan_name']}
                        </Link>
                    </h6>
                    <span>{time}</span>
                    <img alt={item['user.model.plan_name']} src={item['uri']}></img>
                    <p>{ item.post_type_id === 1
                            ? item.caption
                            : (item.post_type_id === 2
                                ? "Has Posted an Image"
                                : "Has Posted a Video")
                        }
                        { item.post_type_id !== 1 && item.caption !== '' ?
                            <i>: {item.caption}</i>
                        : null }
                    </p>
                </div>
            );
        });
    }

    drawMessages(){
        const conversations = [...this.state.conversations];
        return conversations.map((item,index) => {
            let time = moment(item.created_at).format('DD MMM, YYYY, hh:mm:ss');
            return (
                <div className="notification-item item-msg" key={index} onClick={(e) => this.openChat(e, item.destModel)}>
                    <img alt={item.destName} src={item.destImage}></img>
                    <div>
                        <h6>
                            {item.destName}
                        </h6>
                        <p>{item.message}</p>
                        <span>{time}</span>
                    </div>
                </div>
            )
        });
    }

    closeChat(e){
        this.setState({chat : null});
    }
    changeNotification = async (e, index) => {
        const subs = [...this.state.subs];
        axios.put('/api/user/subscription/notification/',{notification: !subs[index]["notification"], model : subs[index]["model_id"]}).then( (resp) => {
            if(resp.data.Success){

                subs[index]["notification"] = !subs[index]["notification"];
                this.setState({subs : subs})
            }
        })

    }
    drawSubscriptions(){
        const subs = [...this.state.subs];
        return subs.map((item,index) => {
            let time = moment(item['created_at']).format('DD MMM, YYYY');
            return (
                <div className="notification-item item-sub" key={index}>
                    <img alt={item['model.plan_name']} src={item['model.user.user_profile.profile_galleries.file.uri']}></img>
                    <div className='subsLink'>
                        <h6>
                            <Link id='modelname' to={"/" + item['model.user.username']}>
                                {item['model.plan_name']}
                            </Link>
                        </h6>
                        <p>Subscription Date: <span>{time}</span></p>
                        <p>Subscription ID: <span>{item['ccbill_confirmation_id']}</span></p>
                        <span className="price">${item['subscription_cost.base_price']}/ month</span>
                        <Checkbox value="1" label="Receive email notification from this profile" checked={item["notification"] ? true : false} onChange={(e) => this.changeNotification(e, index)}/>
                        <Button
                            onClick={this.setSubscriptionCancel}
                            href="#cancelModal"
                            className="modal-trigger"
                            data-sub-id={item['ccbill_confirmation_id']}>
                            Cancel
                        </Button>
                    </div>
                </div>
            )
        });
    }

    render() {
        return (
            <div className='main main-content'>
                <Navbar user={this.state.user}/>
                <div className="main-container">
                    <div className="container-fluid">
                        <h2 className="main-title">MY ACCOUNT</h2>

                        <ul className="tab-menu">
                            <li
                                className={(this.state.tab === 0) ? "active" : null} onClick={ (e) => this.changeTab(e,0)}>
                                <a href="#MyFeed">My Feed</a></li>
                            <li
                                className={(this.state.tab === 1) ? "active" : null} onClick={ (e) => this.changeTab(e,1)}>
                                <a href="#ViewSubscriptions">View Subscriptions</a>
                            </li>
                            <li
                                className={(this.state.tab === 2) ? "active" : null} onClick={ (e) => this.changeTab(e,2)}>
                                <a href="#Messages">Messages</a>
                            </li>
                        </ul>

                        <div className="tab-container">
                            {(this.state.tab === 0)
                                ? <div className="notifications">
                                        <p className="tab-title">My Feed</p>
                                        {this.drawFeed()}
                                        {this.state.feed.length > this.state.lastLimit ?
                                            <Button className='more' onClick={(e)=>this.morePosts(e,this.state.limited)}>Ver Más</Button>
                                        : null}
                                    </div>
                                : (this.state.tab === 1)
                                ? <div className="notifications">
                                    <p className="tab-title">Subscription List</p>
                                    {this.drawSubscriptions()}
                                </div>
                                : <div className="notifications">
                                    <p className="tab-title">Messages</p>
                                    {this.state.chat
                                        ? <Messages
                                            user={this.state.chat}
                                            closeChat={this.closeChat}
                                            token={this.state.token}
                                            image={this.state.user["uri"]}
                                            auth={this.state.user.user_id}/>
                                        : this.drawMessages()
                                    }
                                    {!this.state.chat && this.state.conversations.length > this.state.lastLimitMsg ?
                                        <Button className='more' onClick={(e)=>this.moreMessages(e,this.state.limitedMsg)}>Ver Más</Button>
                                    : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <CancelSubscription sub_id_to_cancel={this.state.sub_id_to_cancel} />
            </div>
        );
    }
}

export default Feed;