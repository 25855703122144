import React, {Component} from 'react';
import {Icon, Modal,Button,TextInput} from 'react-materialize';
import './style.css';

class ModalEditName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayName : props.displayName
        }

    }
    componentDidMount() {
        
    }
    handleChangeName = async (e) =>{
        this.setState({displayName : e.target.value});
    }
    render (){
        return (
            <Modal className="modal-confirm confirm-dark" options={{dismissible : false}} trigger={this.props.trigger} header="Edit Information">
                <span className="close modal-close"><Icon>cancel</Icon></span>
                <label>Display Name</label>
                <TextInput name="display_name" defaultValue={this.props.displayName} onKeyUp={(e) => this.handleChangeName(e)}/>
                <div className="actions">
                    <ul>
                        <li><Button className="go modal-close" onClick={(e) => this.props.editNameModal(e, this.state.displayName)}>Save</Button></li>
                        <li><Button className="cancel modal-close" >Cancel</Button></li>
                    </ul>
                </div>
            </Modal>
        )
    };
}
export default ModalEditName;