import React, { Component } from 'react';
import {
    Navbar, Icon
} from 'react-materialize';
import { Link } from "react-router-dom";
import axios from "axios";


class Sidenav extends Component {

    constructor(props) {
        super();
    }

    logout() {
        axios.get('/api/home/logout').then(res => {
            window.location = "/";
        });
    }

    render(){

        return(
            <>
                <Navbar className="admin-menu"
                    fixed={true}
                    brand={
                        <img className='logo'
                            src='https://storage.googleapis.com/streamx-251917.appspot.com/public/img/logo-new.png' alt='logo' />
                    }
                    alignLinks="right">
                    <ul className="right">
                        <li>
                            <Link to="/admin">
                                Dashboard <Icon right> dashboard </Icon>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/subscriptions">
                                Subscriptions <Icon right> how_to_vote </Icon>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/models">
                                Models <Icon right> star </Icon>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/posts">
                                Posts <Icon right> image </Icon>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/users">
                                Users <Icon right> supervisor_account </Icon>
                            </Link>
                        </li>

                        <li>
                            <a href="#LogOut" onClick={this.logout}>Logout <Icon right> exit_to_app </Icon></a>
                        </li>
                    </ul>
                </Navbar>
            </>
        );

    }

}

export default Sidenav;