import React, {Component} from 'react';
import {Textarea, Button, Icon} from 'react-materialize';
import axios from 'axios';
import moment from 'moment';
import './style.css'
import io from "socket.io-client";
const userIcon = 'https://storage.googleapis.com/streamx-251917.appspot.com/public/user.png';

class Messages extends Component {
	constructor(props){
		super(props);
		this.state = {
			messages : [],
			message : "",
			token : null,
			limit: 15,
            offset: 0,
            more: true
		}
		this.endChat = this.endChat.bind(this);
		this.socket = io('/',{
            path: '/api/messages/send/',
            query: 'user='+props.user+'&token='+props.token
        });
        this.socket.on('RECEIVE_MESSAGE', (data)=>{
        	const messages = [...this.state.messages];
            messages.push({message : data.message , own : data.chat === props.user ? 1 : 0, image : data.image});
            this.setState({messages : messages});
            var objDiv = document.getElementById("messages-container");
			objDiv.scrollTop = objDiv.scrollHeight;
        });
	}
	componentDidMount(){
		const textarea = document.getElementById("message-content");
        textarea.addEventListener('keydown', async (ev)=>{
            const sizeText = textarea.textContent.length;
            //if(((ev.key !== 'Backspace' || ev.which!==8) && sizeText>320) || this.state.answeredLimit>15){
            if(((ev.key !== 'Backspace' || ev.which!==8) && sizeText>320)){
                ev.preventDefault();
            }
            if (ev.key === 'Enter') {
                ev.preventDefault();
                await this.sendMessage(ev)
                textarea.innerHTML = '';
            }
        });
        this.getMessages();
	}
	getMessages = async () => {
		let offset = this.state.offset;
		let limit = this.state.limit;
		let currMessages = [...this.state.messages];
		await axios.post("/api/messages/getMessages", {chat : this.props.user,limit: limit, offset : offset})
		.then( async (res) => {
			let newMessages = res.data;
			if(newMessages.length === 0){
				this.setState({more : false});
				document.querySelector('#messages-container').classList.add("end")
				this.upscroll();
			}
			else{
				this.setState({messages : newMessages.concat(currMessages)});
				let newOffset = offset + limit
				this.setState({offset : newOffset})
				setTimeout(()=>{
					if(newOffset === limit){
		            	this.downscroll();
		            }
		            else{
		            	this.upscroll();
		            }
		        },40);
			}
		})

	}
	downscroll = async () => {
      const wrappedElement = document.querySelector('#messages-container');
      if(wrappedElement){
          let height = wrappedElement.scrollHeight;
          wrappedElement.scrollTo(0, height)
      }
    }
	upscroll = async () => {
      const wrappedElement = document.querySelector('#messages-container');
      if(wrappedElement){
          wrappedElement.scrollTo(0, 0)
      }
    }
	endChat = async () => {
		this.socket.disconnect();
		this.props.closeChat();
	}
	sendMessage = async (e) => {
		e.preventDefault();
		const message = document.getElementById("message-content").value;
		document.getElementById("message-content").value="";
		let messageSocket = {message : message, chat : this.props.user, image : this.props.image, token : this.props.token, auth: this.props.auth}
		axios.post("/api/messages/send",messageSocket).then(async (res) => {
			if(res.data.message){
				this.socket.emit('SEND_MESSAGE', res.data.message);
			}
			//console.log(res.data)
		})
	}
	renderMessages = () => {
		const messages = [...this.state.messages];
		return messages.map((item,index) => {
            let time = moment(item.created_at).format('hh:mm');
            return (
                <div key={index} className={"message" + (item.own ? " own" : "")}>
					<img src={item.image ? item.image : userIcon} alt=""/>
					<p>
						{item.message}
						<span>{time}</span>
					</p>

				</div>
            )
        });
	}
	render() {
		return (
			<div className='message-feed'>
				<Button onClick={this.endChat}><Icon left>arrow_back</Icon>Back</Button>
				<div className="chat-container">
					<div className="messages inner" id="messages-container">
					{this.state.more ?
						<Button className='more' onClick={(e) => this.getMessages()}>Ver Más</Button>
						: null
					}
						{this.renderMessages()}
					</div>
					<div className="chat-text">
						<Textarea id="message-content"/>
						<Button onClick={(e) => this.sendMessage(e)}>Send<Icon right>send</Icon></Button>
					</div>
				</div>
			</div>
		)
	}

}
export default Messages;