import React, { Component } from 'react';
import Navbar from '../../../components/Main/Navbar';
import './style.css';

class Privacy extends Component {
    constructor(){
        super();
        this.state = {
        }
    }
    componentDidMount() {
        if(this.props.authenticated !== null){
            this.setState({user : this.props.authenticated.username,email: this.props.authenticated.email});
        }
    }
    
    render() {
        return (
            <div className="main-p">
                <Navbar user={this.state.user} />
                <div className="container main-container-privacy">
                    <div className="privacy-container ">
                        <h4 className='center-align' style={{'font-weight':'bold'}}>
                            Privacy Policy
                        </h4>
                        <p>
                            We respect the privacy of our members and users of the Streamx service. We have created this Privacy Policy to inform you as to how we collect, store, and use data resulting from your use of Streamx and any of its services. This Privacy Policy is an addition to our Terms of Service (the "Terms"), and together with the Terms controls your relationship with Streamx and the Terms are expressly incorporate herein by this reference. This Privacy Policy and any dispute concerning it are governed by the dispute resolution procedures, arbitration agreement, and class-action waiver contained within the Terms.
                        </p>
                        <p>
                            This Privacy Policy may be updated from time to time, though it is current as of the "Last Update" date listed at the bottom of this policy. We will let you know anytime we make material changes to this Privacy Policy. Any changes will be made according to the modification provisions listed below. Having said that, you should review this policy periodically.
                        </p>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>I. Personally Identifiable Information ("PII") We Collect and Use</h6>
                            <p>
                                In using the Streamx website and/or its services, you acknowledge and agree that Streamx collects PII as outlined below when you create an account on Streamx. PII is provide by you and collected when you use this website, purchase a subscription, or sell a subscription. Streamx does not utilize or share any PII with third parties except as herein described.
                            </p>
                            <p>
                                PII is information that can be used to identify or contact an individual. Such information may include your name, telephone number, address, email address, or credit card information. If you are a resident of the European Union ("EU") PII is "personal data" in accord with the definition in article 2 a) of the Directive 95/46 of 24 October 1995. You are not required to provide any PII we request, but if you fail to provide us with your PII in certain circumstances and upon request, we may not be able to provide you with services via the site, or be able to facilitate your credit card purchases.
                            </p>
                            <p>
                                The Streamx website may include links to third-party websites. Except as set forth herein, we do not share any of your PII with them. Streamx does not control, endorse, or supervise these third-party sites and such sites may collect PII when you click an external link or visit a third-party website. This Privacy Policy does not govern your interaction with any third-party site, and you should consult with the third-party website regarding their privacy policies and terms of service.
                            </p>
                            In limited circumstances, we may also ask for government identification.
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>II. How We Use Your PII</h6>
                            <p>
                                Your PII is used internally, and allows us to facilitate transactions and to provide and deliver you access to your profile, content, and subscriptions of your choosing. It also allows us to contact you and answer any requests or comments you may have.

                                We also use PII internally to create, develop, and improve our fraud monitoring and loss prevention policies. We also use your PII internally for audits and analysis to keep to site and the Streamx services running as smoothly as possible.
                            </p>
                            <p>
                                Streamx may use the PII you provide in conjunction with third parties that are under contract with Streamx such as our payment processors and vendors who ensure you have access to your subscriptions, content, and profile. Streamx only provides your PII to the extent necessary to ensure you are provided with these services. Streamx never provides your PII to third parties for marketing or advertising purposes.

                                Similarly, we use PII, including your date of birth, to ensure you are of suitable age to access the site and the various content available through profile owners on Streamx.
                            </p>
                            <p>
                                In limited circumstances, we may also use your PII to contact you and give you important notices, including but not limited to fraud warnings, changes in the Streamx Terms, and changes to this Privacy Policy. While you may opt out of some communications with Streamx, you may not opt out of communications with Streamx related to the Terms or this Privacy Policy, as these form the basis of your relationship with Streamx and govern our interactions.

                                If you are a profile owner, some of the PII you provide may be searchable to subscribers and users of Streamx. Searchable PII will be limited to user name or first and/or last name.
                            </p>
                            <p>
                                We may create anonymous data ("Anonymous Data") logs (logs that exclude PII) to help us conduct analysis that helps us monitor and improve Streamx. We reserve all rights to use Anonymous Data for any purpose and disclose Anonymous Data to third partied at our sole discretion.

                                It is important to note that we may access, utilize, and release your PII to comply with court orders, legal process, litigation and/or requests from governmental agencies or authorities which require us to disclose your PII. We may also disclose your information if we determine that disclosure is necessary to comply with applicable law or suspicion of violation of law.
                            </p>
                            <p>
                                If Streamx were to merge, sell, or otherwise reorganize in any way, we may transfer and deliver your PII to the relevant third party. Such transfers are ordinarily part of any business transfers.

                                Aside from the instances specifically addressed in this Privacy Policy, you will receive notice when your PII may go to a third party, and you will be provided with an opportunity to withhold your consent to the transfer of any such information to a third party.
                            </p>
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>III. Cookies and Other Technology</h6>
                            <p>
                                Streamx's websites, products, services, and communications may use cookies and other technologies such as pixel tags and web beacons. Cookies are a form of technology that contains electronic information that can be used to help us customize your individual use of our website by informing us of what parts of the website you have utilized or visited, keep records and logs of your access to the site, and/or store information required by a user on an ongoing basis. Streamx utilizes cookies to remember your PII to tailor your experience to you, including so that you do not need to repeatedly re-enter your information, and/or username and password each time you access a new page on the website. We regard information collected by cookies and similar technologies as non-personal information. If, according to the local laws, Internet Protocol ("IP") addresses or similar identifying information is considered personally identifiable information, we will also treat such information as personally identifiable.
                            </p>
                            <p>
                                Cookies can be disabled by changing the corresponding settings in your web browser. It is important to note that some parts of the site, or the services provided by Streamx may not be accessible once cookies are disabled.
                            </p>
                            <p>
                                Some information we collect is gathered automatically and stored in log files. We may use third party analytics that utilize cookies to collect non-personally identifiable information regarding your use of the website.
                            </p>
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>IV. Children Under the Age of 13</h6>
                            <p>
                                Children under the age of 13 are not allowed to use the Streamx website. Streamx will not knowingly collect any PII from children under the age of 13. In accordance with the Streamx Terms, Streamx encourages parents to monitor their child's activities online and to counsel them to never give out PII while online. If we become aware that a child under 13 has provided us with personal information without parental consent, we take steps to remove such information and terminate the child's account. Children under the age of 18 may use this site only with the involvement of a parent or guardian. Parents who may be concerned for the PII of their children on Streamx, should contact us at help@streamx.tv.
                            </p>
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>V. Opting-Out of Communications With Us</h6>
                            <p>
                                As discussed above, you have the ability to opt-out of receiving promotional or advertising communications from us. If you would like to opt-out of promotional communications, please contact us at help@streamx.tv so that we can provide you with instructions for discontinuing such communications.
                            </p>
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>VI. Storing and Securing Your PII</h6>
                            <p>
                                We endeavor to protect the security of the PII you provide to us by employing reasonable measures to ensure your information remains protected. Unfortunately, the transmission of information via the Internet is not entirely secure. Streamx uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your PII. We cannot, however, guarantee or warrant the security of any information you transmit to Streamx, and you do so at your own risk.
                            </p>
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>VII. Information You Can Access</h6>
                            <p>
                                Concerns about the type and accuracy of PII collected by Streamx, or if you wish to review or amend any information you have provided us with, or if you wish for your PII to be purged, terminated, or otherwise deleted from our records, you can contact us at help@streamx.tv. We reserve the right not to comply with any requests that would be unreasonably impractical, repetitive, or would infringe the privacy of others. Additionally, we may retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with your use of the site, or to comply with legal obligations.
                            </p>
                        </div>
                        <div>
                            <h6 style={{'font-weight':'bold'}}>VIII. Conditions, Use, and Modifications</h6>
                            <p>
                                By accessing the site or utilizing any services therein, your use and any dispute relating to or arising from that use are controlled by this Privacy Policy and the Streamx Terms of Service, including but not limited to, limitation of damages, arbitration, and class action waiver.
                            </p>
                            <p>If you have any questions or concerns regarding your PII please do not hesitate to contact us at help@streamx.tv and we will address it.</p>
                            <p>
                                As outlined in our Terms, we may from time-to-time update our Terms or this Privacy Policy. The right to amend the Terms and this Privacy Policy includes the right to modify, add to, or remove clauses. Except as otherwise stated in the Terms or this Privacy Policy, all amended terms shall be effective immediately after they are posted. Additionally, you will be provided with a notice of the change in Terms or this Privacy Policy and will be asked to review and affirmatively accept any such changes by clicking an "I accept" or "I agree" box. Regardless of our efforts to provide reasonable notice of any change in Terms, you should review these Terms periodically.
                            </p>
                            <p>
                                Any changes that are made to the Terms or this Privacy Policy will not apply retroactively, and will not apply to any dispute of which both parties had actual notice on or prior to the date of the change or amendment.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    &#169; Copyright streamx, 2019 All right reserved.
                </div>
            </div>
        );
    }
}

export default Privacy;